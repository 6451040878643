import { BaseText, HStack, Switch, VStack, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';

interface SettingListItemProps {
  title: string;
  description: string;
  children?: React.ReactNode;
  disabled?: boolean;
  isSwitchOn: boolean;
  onSwitchClick: () => void;
}

const SettingListItem = ({
  title,
  description,
  children,
  disabled,
  isSwitchOn,
  onSwitchClick,
}: SettingListItemProps) => {
  return (
    <Container disabled={disabled} p={16} spacing={16} style={{ background: semantic_colors.background.surface }}>
      <HStack alignment='center' spacing='auto'>
        <VStack spacing={4}>
          <BaseText kind='Heading_15_SemiBold'>{title}</BaseText>
          <BaseText kind='Body_12_Regular' color={semantic_colors.content.secondary}>
            {description}
          </BaseText>
        </VStack>
        <Switch disabled={disabled} isOn={isSwitchOn} size='large' onClick={onSwitchClick} />
      </HStack>
      {children}
    </Container>
  );
};

export default SettingListItem;

const Container = styled(VStack)<{ disabled?: boolean }>`
  .pds-text {
    color: ${({ disabled }) => disabled && semantic_colors.content.disabled};
  }
`;
