import { BaseText, PageHeader, SectionTitle, VStack, colors, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ZIGZIN_GUIDE_OPTIONS } from '../constants';
import { HelpConstantLink } from '@/components/common/HelpConstantLink';

const StockPageHeader = () => {
  const { t } = useTranslation('zigzin');

  return (
    <PageHeader border={false}>
      <SectionTitle
        title={t('재고 관리')}
        description={
          <VStack>
            <StyledPageHeaderDescriptionList>
              <li>
                <BaseText kind='Body_13_Regular' color={colors.gray700}>
                  {t('직진배송 창고 내 재고 현황을 확인할 수 있습니다.')}
                </BaseText>
              </li>
              <li>
                <BaseText kind='Body_13_Regular' color={colors.gray700}>
                  {t('직진배송 재고 관련 문의가 있으실 경우, 오른쪽 하단 채팅 상담으로 문의주세요.')}
                </BaseText>
              </li>
              <li>
                <HelpConstantLink options={ZIGZIN_GUIDE_OPTIONS} linkKey='운영 가이드'>
                  <BaseText kind='Body_13_SemiBold' color={semantic_colors.accent.primary}>
                    {t('직진배송 운영가이드')}
                  </BaseText>
                </HelpConstantLink>
              </li>
            </StyledPageHeaderDescriptionList>
          </VStack>
        }
      />
    </PageHeader>
  );
};

export default StockPageHeader;

const StyledPageHeaderDescriptionList = styled.ul`
  > li::before {
    content: '-';
    padding-right: 4px;
  }
`;
