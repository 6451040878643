import { ShippingInfoTabType } from '@/components/info/shipping_template/list/constants';

export * from '@/components/catalog/constants/URL';

export const VIDEO_LIST_URL = '/video/video_list';
export const VIDEO_DETAIL_URL = '/video/video_detail';
export const PRODUCT_REVIEW_LIST_URL = '/product_review';
export const IMPORT_EXTERNAL_REVIEW_URL = '/import_external_review';
export const PRODUCT_REVIEW_DETAIL_URL = '/product_review/detail';
export const REVIEW_EVENT_LIST_URL = '/review_event_list';
export const BEST_REVIEW_EVENT_URL = `${REVIEW_EVENT_LIST_URL}/best_review`;
export const FIRST_REVIEW_EVENT_URL = `${REVIEW_EVENT_LIST_URL}/first_review`;
export const ORDER_INQUIRY_DETAIL_URL = '/order_inquiry/detail';
export const ITEM_QUESTION_DETAIL_URL = '/item_question/detail';
export const STORE_PICK_EXHIBITION_URL = '/promotion/exhibition/apply';
export const COUPON_REPORT_URL = '/coupon/report';
export const INFO_MANAGE_MODEL_URL = '/info/manage_model';
export const INFO_SHIPPING_ADDRESS_URL = '/info/shipping_address';
export const INFO_SHIPPING_TEMPLATE_DETAIL_URL = '/info/shipping_template_detail';
export const INFO_SHIPPING_TEMPLATE_LIST_URL = '/info/shipping_template';

export const PROMOTION_POINT_LIST_URL = '/point/list';

export enum WarehousedInUrl {
  INSIGHT_RECOMMENDED = '/zigzin/insight/recommended',
  INSIGHT_TODAY = '/zigzin/insight/today',
  POLICY = '/zigzin_policy',
  LIST_V1 = '/zigzin/warehoused_in/v1',
  LIST_V2 = '/zigzin/warehoused_in/v2',
  REQUEST_LIST_V2 = '/zigzin/warehoused_in/v2/request_list',
  BASIC_INFO = '/zigzin/warehoused_in/v2/basic_info',
  PICK_ITEM_V2 = '/zigzin/warehoused_in/v2/pick_item',
  SET_ORDERER_V2 = '/zigzin/warehoused_in/v2/set_orderer',
  REQUEST_INVOICE_V2 = '/zigzin/warehoused_in/v2/request_invoice',
  EXCEL_UPLOAD = '/zigzin/warehoused_in/v2/excel_upload',
  EXCEL_UPLOAD_SET_ORDERER_V2 = '/zigzin/warehoused_in/v2/excel_upload/set_orderer',
  EXCEL_UPLOAD_REQUEST_INVOICE_V2 = '/zigzin/warehoused_in/v2/excel_upload/request_invoice',
  ADDITIONAL_ITEM = '/zigzin/additional_item',
  ZIGZIN_OUTLET_PROMOTION = '/zigzin/outlet_promotion',
  ZIGZIN_FEE_DISCOUNT = '/zigzin/fee_discount',
  ZIGZIN_OBSOLETE_STOCK_PROMOTION = '/zigzin/obsolete_stock_promotion',
  PENALTY_ITEM = '/zigzin/penalty_item',
  INBOUND_PROBLEM_SOLVED = '/zigzin/inbound_problem_solved',
  PENLATY_ITEM_OUT_OF_SYNC = '/zigzin/penalty_item?tab_type=out_synced',
}

export enum CarryOutUrl {
  LIST = '/zigzin/carry_out',
  PICK_ITEM = '/zigzin/carry_out/pick_item',
  SET_ORDERER = '/zigzin/carry_out/set_orderer',
  REQUEST_INVOICE = '/zigzin/carry_out/request_invoice',
}

export enum StockUrl {
  STOCK = '/zigzin/stock',
  CARRY_OUT = '/zigzin/stock/carry_out',
}

export enum OrderItemListUrl {
  SEARCH = '/order_item/list/search',
  PENDING = '/order_item/list/pending',
  SHIPMENT = '/order_item/list/shipment',
  PRE_SHIPMENT = '/order_item/list/pre_shipment',
  CONFIRM = '/order_item/list/confirm',
  CANCEL = '/order_item/list/cancel',
  RETURN = '/order_item/list/return',
  EXCHANGE = '/order_item/list/exchange',
}

export enum OrderLinkedListUrl {
  QUESTION = '/order_linked_management/list/question',
  SEARCH = '/order_linked_management/list/search',
  SHIPMENT = '/order_linked_management/list/shipment',
  RETURN = '/order_linked_management/list/return',
  CANCEL = '/order_linked_management/list/cancel',
  EXCHANGE = '/order_linked_management/list/exchange',
  EC_SETTLEMENT = '/order_linked_management/list/ec_settlement',
  EC_TAX_INVOICE = '/order_linked_management/list/ec_tax_invoice',
  EC_VAT_REFERENCE = '/order_linked_management/list/ec_vat_reference',
}

export enum PartnerPlusNavUrl {
  INTRO = '/partner-plus/intro',
  MANAGEMENT_BENEFIT = '/partner-plus/management/benefit',
  MANAGEMENT_SUBSCRIPTION = '/partner-plus/management/subscription',
  MANAGEMENT_HISTORY = '/partner-plus/management/history',
  NOTICE = '/partner-plus/management/notice',
  NOTICE_DETAIL = '/partner-plus/management/notice/:id',
  UNSUBSCRIPTION_PREVENTION = '/partner-plus/subscription/cancel/notification',
  UNSUBSCRIPTION = '/partner-plus/subscription/cancel',
  SUBSCRIPTION = '/partner-plus/subscription',
}

export enum InsightNavUrl {
  STARTER = '/insight/intro/starter',
  INTRO = '/insight/intro',
  PLAN = '/insight/plan',
  CHECKLIST = '/insight/starter-plan/checklist',
  ITEM = '/insight/starter-plan/item',
}

export enum EcTaxInvoiceUrl {
  LIST = '/ec_tax_invoice',
  DEDUCTION_DETAIL = '/deduction-detail',
  REBATE_DETAIL = '/rebate-detail',
}

export function getProductReviewDetailURL(id: string) {
  return `${PRODUCT_REVIEW_DETAIL_URL}/${id}`;
}
export function getBestReviewEventDetailURL(id: string) {
  return `${BEST_REVIEW_EVENT_URL}/${id}`;
}
export function getReviewEventReviewListURL(id: string) {
  return `${REVIEW_EVENT_LIST_URL}/${id}/review_list`;
}
export function getReviewEventReviewDetailURL(event_id: string, review_id: string) {
  return `${REVIEW_EVENT_LIST_URL}/${event_id}/review_list/${review_id}`;
}
export function getFirstReviewEventDetailURL(id: string) {
  return `${FIRST_REVIEW_EVENT_URL}/${id}`;
}
export function getCouponReportURL(id: string) {
  return `${COUPON_REPORT_URL}/${id}`;
}

export function getEcTaxInvoiceDeductionDetailURL(id: string) {
  return `${EcTaxInvoiceUrl.DEDUCTION_DETAIL}/${id}`;
}

export function getEcTaxInvoiceRebateDetailURL(id: string) {
  return `${EcTaxInvoiceUrl.REBATE_DETAIL}/${id}`;
}

export function getInfoManageModelDetailURL(id: string) {
  return `${INFO_MANAGE_MODEL_URL}?mode=model_detail&id=${id}`;
}

export function getVideoDetailURL(id: string) {
  return `${VIDEO_DETAIL_URL}/${id}`;
}

export function getPointPaymentListURL(id: string) {
  return `${VIDEO_DETAIL_URL}/${id}`;
}

export function getInfoShippingTemplateListURL(type: ShippingInfoTabType) {
  return `/info/${type}`;
}

export function getOrderInquiryDetailURL(id: string) {
  return `${ORDER_INQUIRY_DETAIL_URL}/${id}`;
}

export function getItemQuestionDetailURL(id: string) {
  return `${ITEM_QUESTION_DETAIL_URL}/${id}`;
}
