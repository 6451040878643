import { flatMapDepth } from 'lodash';
import { MenuEntry } from './types';
import { isApp } from '@/utils/app_utils/conditions';

export function hasSelectedChildren(entry: MenuEntry, selected_id: string | null): boolean {
  if (entry.id === selected_id) return true;
  if (entry.items == null) return false;
  return entry.items.some((child) => hasSelectedChildren(child, selected_id));
}

export function filterAllowedMenuEntries(entries: MenuEntry[]): MenuEntry[] {
  return entries
    .map((entry) => {
      if (entry.items == null) return entry;
      return { ...entry, items: filterAllowedMenuEntries(entry.items) };
    })
    .filter((entry) => {
      if (entry.is_allowed === false) return false;
      if (entry.items != null && entry.items.length === 0) return false;

      return isApp() ? Boolean(entry.is_support_app) : true;
    });
}

export function getLastMenuItems(filtered_entries: MenuEntry[]) {
  return flatMapDepth(
    filtered_entries,
    (obj) => {
      if (obj.items) {
        return getLastMenuItems(obj.items);
      }
      return obj;
    },
    1,
  );
}
