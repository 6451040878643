import { useAtom } from 'jotai';
import isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { app_push_system_enabled_atom } from '@/atoms/app_push_system_enabled';
import { useAccount } from '@/hooks/useAccount';
import { useInfo } from '@/hooks/useInfo';
import { usePbl } from '@/hooks/usePbl';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { PartnersBridge } from '@/utils/app_utils';
import {
  api_updatePartnerCenterAppFcmToken,
  useGetPartnerCenterAppPushSystemAgreementQuery,
  useUpdatePartnerCenterAppPushSystemAgreementMutation,
} from 'api';

const useSystemPushNotificationCheckEffect = () => {
  const { is_logined } = useAccount();
  const prefix = useShopPrefix();
  const { data, refetch } = useGetPartnerCenterAppPushSystemAgreementQuery(undefined, {
    enabled: is_logined,
    onError() {},
  });
  const { mutateAsync } = useUpdatePartnerCenterAppPushSystemAgreementMutation({ onError() {} });
  const [is_app_push_system_enabled, setIsAppPushSystemEnabled] = useAtom(app_push_system_enabled_atom);

  const checkEnabled = async () => {
    const { enabled } = await PartnersBridge.getPushNotificationEnabled();
    setIsAppPushSystemEnabled(enabled);
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      refetch();
      checkEnabled();
    }
  };

  useEffect(() => {
    checkEnabled();

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (!is_logined || isNil(is_app_push_system_enabled)) return;

    if (
      isNil(data?.getPartnerCenterAppPushSystemAgreement) ||
      (typeof data?.getPartnerCenterAppPushSystemAgreement === 'boolean' &&
        data?.getPartnerCenterAppPushSystemAgreement !== is_app_push_system_enabled)
    ) {
      mutateAsync({ input: { is_agree: is_app_push_system_enabled } }).then(() => {
        refetch();
      });
      return;
    }
  }, [data, is_logined, is_app_push_system_enabled]);

  useEffect(() => {
    // NOTE: meta 라우트인 경우 fcm token 갱신 안함
    if (!is_logined || !prefix) return;

    PartnersBridge.getFcmTokenWithDeviceInfo().then(async (data) => {
      try {
        await api_updatePartnerCenterAppFcmToken(
          {
            input: {
              fcm_token: data.fcm_token,
              app_version: data.app_version,
              os_version: data.os_version,
              device_model: data.device_model,
              device_language: data.device_language,
            },
          },
          { no_alert: true },
        );
      } catch (error) {
        console.error(error);
      }
    });
  }, [is_logined, prefix]);
};

const useAppBottomNaviCheckEffect = () => {
  const { is_logined } = useAccount();
  const prefix = useShopPrefix();

  useEffect(() => {
    // NOTE: 스토어 진입 이전에는 하단 네비게이션을 숨김
    PartnersBridge.setVisibleBottomNavi(Boolean(is_logined && prefix));
  }, [is_logined, prefix]);
};

const useDeepLinkPblEffect = () => {
  const { pbl } = usePbl();
  const { info } = useInfo();
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      if (params.get('partners_referrer') === 'push') {
        pbl({
          category: 'deeplink',
          navigation: 'push',
          navigation_sub: { shop_id: info.id || '', page_url: window.location.href },
          object_type: 'link',
          extra: JSON.stringify({
            notification_id: params.get('partners_extra_notification_id'),
            notification_group_id: params.get('partners_extra_notification_group_id'),
          }),
        });
      }
    }
  }, []);
};

const AppChecker = () => {
  useSystemPushNotificationCheckEffect();
  useAppBottomNaviCheckEffect();
  useDeepLinkPblEffect();

  return null;
};

export default AppChecker;
