import { Badge, Tooltip, semantic_colors, text_styles } from '@croquiscom/pds';
import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useScrollDebounce } from '../hooks/useScrollDebounce';
import { main_domain_atom } from '@/atoms/main_domain';
import { ShopLink } from '@/components/route/ShopLink';
import { SiteId } from '@/constants/site';
import { useInfo } from '@/hooks/useInfo';
import { useOnceTooltip } from '@/hooks/useOnceTooltip';

export interface ShopInfoProfileProps {}

export const ShopInfoProfile: React.FC<ShopInfoProfileProps> = () => {
  const main_domain = useAtomValue(main_domain_atom);
  const { t } = useTranslation('main_frame');
  const { info } = useInfo();
  const { is_show, hideTooltip } = useOnceTooltip({
    key: `MainFrame.ProfileTooltip`,
  });
  const is_show_scroll = useScrollDebounce(is_show);
  const [is_show_top, setIsShowTop] = useState(false);
  const divRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!is_show) return;
    const elem = divRef.current;
    if (elem == null) return;
    const parentElem = elem.offsetParent;
    if (parentElem == null) return;
    const handleScroll = () => {
      setIsShowTop(parentElem.scrollTop === 0);
    };
    handleScroll();
    parentElem.addEventListener('scroll', handleScroll);
    return () => {
      parentElem.removeEventListener('scroll', handleScroll);
    };
  }, [is_show]);
  return (
    <Tooltip
      openerTriggerEvent='none'
      canClickOutside={false}
      kind='brand'
      placement='right'
      opened={is_show_scroll && is_show_top}
      onClose={() => is_show_scroll && is_show_top && hideTooltip()}
      content={
        <Trans t={t}>
          쇼핑몰명이나 썸네일을 눌러
          <br />
          기존 대시보드(홈) 화면으로 이동할 수 있어요.
          <br />
          또한, 메뉴들도 새롭게 개편되었어요.
          <br />
          자세한 내용은 공지사항을 확인해주세요.
        </Trans>
      }
      closeButton
      zIndex={90}
    >
      <StyledProfile to='/home' onClick={() => hideTooltip()}>
        <StyledImage src={`/api/provider/${main_domain}/typical_image?size=132`} alt='' />
        <StyledContent ref={divRef}>
          <StyledName>{info.name}</StyledName>
          {info.site?.id !== SiteId.지그재그 && (
            <StyledTag>
              <Badge kind='outline' color='blue' size='small'>
                {info.site?.name}
              </Badge>
            </StyledTag>
          )}
        </StyledContent>
      </StyledProfile>
    </Tooltip>
  );
};

const StyledProfile = styled(ShopLink)`
  display: block;
  margin: 0 0 16px;
  text-decoration: none;
`;

const StyledImage = styled.img`
  display: block;
  margin: 0 auto 12px;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  overflow: hidden;
`;

const StyledContent = styled.div`
  margin: 0 auto;
`;

const StyledName = styled.div`
  ${text_styles.Heading_17_Bold};
  color: ${semantic_colors.content.primary};
  word-break: keep-all;
  overflow-wrap: anywhere;
  text-align: center;
`;

const StyledTag = styled.div`
  display: flex;
  justify-content: center;
  margin: 4px 0 0;
`;
