import { CountryCode } from '@/api';

export const PRIMIUM_INSPECT_GUIDE = 'https://drive.google.com/file/d/13X1Kf73wt6xu8EEOI2TkU0XEYNEcvhu9/view';
export const ATTENTION_INSPECT_GUIDE_PAGE = 'https://cf.res.s.zigzag.kr/notice/feca4351e8674c2d8036e8e86d5449bf.pdf';
export const FBK_AD_MANUAL =
  'https://docs.google.com/presentation/d/1Suy0C-fqSzk90KSYF2RfeiqD9SmSa5KgyXNGuRDKxpo/edit#slide=id.p1';
export const PREMIUM_AD_MANUAL = 'https://cf.res.s.zigzag.kr/notice/c0c5cd584aad46f7b6e6f6ca29ab3efa.pdf';
export const AD_PRIMIUM_INTRODUCTION = 'https://cf.res.s.zigzag.kr/notice/997a419ee26840508084622f2840a801.pdf';
export const ZIGZAG_SHOP_GUIDE_URL = 'https://drive.google.com/file/d/10B_knAcHnAZ4QmI9PDJvcMi2x7oM2lWB/view'; // 지그재그 소개서
export const ZIGZAG_AD_CONTENTS_GUIDE_URL =
  'https://docs.google.com/presentation/d/1bcXuXFYKa0d_Unxzm6d5BBAdot6kjL7QijSlqDE2ptk/edit#slide=id.p1';
export const FBK_AD_CONTENTS_GUIDE_URL =
  'https://docs.google.com/presentation/d/1T7f30-bqsuWcOcyU7VA7_dHP2JJQivoPb0zm06EUwvI/edit#slide=id.p1';
export const CAFE24_GOODS_API_FAQ_URL =
  'https://docs.google.com/presentation/d/1N4vV_d0H1dqQahyuUzzasg9zD2d-3kbKR8n3Xf1q9lw';
export const MAKESHOP_GOODS_API_FAQ_URL =
  'https://docs.google.com/presentation/d/1j6wvHWiKIlF74foRFuGoE9X3AWthh9mmc7FXnKVv084';
export const ZPAY_PARTNER_CENTER_GUIDE_URL =
  'https://kakaostyleincubating.oopy.io/guide?utm_source=admin_faq_btn&utm_medium=banner&utm_campaign=faq_banner&utm_content=admin_page&utm_term=howtoadmin';
export const ZIGZAG_SHOP_SCORE_GUIDE_URL =
  'https://docs.google.com/presentation/d/1XerGcKRmrMboNHhKL4k4NAAWnIqtny4SVCbwZtegzQA/edit#slide=id.g23efbf74297_0_115';
export const ZIGZAG_AD_CONTENTS_INTRODUCTION =
  'https://docs.google.com/presentation/d/1PLUqNbEzFColcWPfYaZ0cxsvKvcHecM01b9lN5Qp9IQ/edit#slide=id.g27431310c23_0_0';
export const ZIGZAG_AD_CONTENTS_MANUAL =
  'https://docs.google.com/presentation/d/1KGRrNjTBtX3ecyIjvDrt3mJJxAv-OpsvRjxr8FIVqyk/edit#slide=id.g294b77c8547_0_0';
export const AGENCY_ADMIN_GUIDE_URL = 'https://cf.res.s.zigzag.kr/notice/ed5f3de493a64fe9b1d00fadbc5c56e6.pdf';
export const AGENCY_POLICY_URL = 'https://cf.res.s.zigzag.kr/notice/6a23ab55c4684074aced22cf4ffca4e3.pdf';
export const SELLER_ITEM_COUPON_EXAMPLE_URL = 'https://cf.res.s.zigzag.kr/seller_item_coupon_example.xlsx';
export const SELLER_PROMOTION_PRICE_EXAMPLE_URL =
  'https://cf.res.s.zigzag.kr/promotion/promotion_price_upload_template_v1.2.xlsx';
export const FBK_INTRODUCTION = 'https://cf.res.s.zigzag.kr/notice/28758f5d6b794789bbf0737b73458210.pdf';
export const FBK_AD_CONTENTS_INTRODUCTION =
  'https://docs.google.com/presentation/d/17CjpqFm5iNARr-wm6LIh6OPImbZa-LYOK4wWgith2c8/edit#slide=id.p1';
export const POSTY_INTRODUCTION = 'https://cf.res.s.zigzag.kr/event_file/fab5dc3fa3117408ec82b4735d2ea526.pdf';

export const ZIGZAG_OPERATION_GUIDE = 'https://cf.res.s.zigzag.kr/notice/8ac5411954b14f29918379828c8a62cf.pdf';
export const POSTY_OPERATION_GUIDE = 'https://cf.res.s.zigzag.kr/notice/0500ba981b104fb291c449afe1b228f8.pdf';

export const ATTENTION_AD_MANUAL = 'https://cf.res.s.zigzag.kr/notice/694ba4a0badb46f69cee8e572cb76fc0.pdf';

export const SHOP_CLOTH_CATEGORY_ID = 1;
export const SHOP_LIFE_CATEGORY_ID = 1026;
export const SHOP_BEAUTY_CATEGORY_ID = 1027;
export const SHOP_KIDS_CATEGORY_ID = 1031;

export const ALPHA_ZIGZIN_SHOP_ID = '20017';
export const PRODUCTION_ZIGZIN_SHOP_ID = '9038';

export const BUSINESS_CLEARANCE_CODE_APPROVED_CONFIRMED_KEY = 'business_clearance_code_approved_confirmed';

export const DEFAULT_SITE_COUNTRY_LIST = Object.values(CountryCode);

export const LEAST_REQUEST_MY_PICK_COUNT = 10;
