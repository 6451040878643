import { t } from 'i18next';
import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ShopSyncErrorMessage } from './ShopSyncErrorMessage';
import { SellerResource, useGetShopSyncSyncedQuery } from '@/api';
import { has_shop_sync_error_atom } from '@/components/info/shop_sync/atoms';
import { useInfo } from '@/hooks/useInfo';

export const SHOP_SOLUTION_NAME = {
  CAFE24: t('info::카페24'),
  MAKESHOP: t('info::메이크샵'),
  WISA: t('info::위사'),
};

export const SHOP_SOLUTION_TITLES = {
  CAFE24: t('info::카페24로부터 상품 연동이 중단되었습니다.'),
  MAKESHOP: t('info::메이크샵으로부터 상품 연동이 중단되었습니다.'),
  WISA: t('info::위사로부터 상품 연동이 중단되었습니다.'),
};

export const ShopSyncErrorSection: React.FC = () => {
  const { t } = useTranslation('info');
  const [has_shop_sync_error, setShopSynced] = useAtom(has_shop_sync_error_atom);
  const { info, isAllowed } = useInfo();
  const is_visible =
    info.shop_sync_allowed && isAllowed([SellerResource.SHOP_SETTINGS, SellerResource.SHOP_SETTINGS_BASIC]);
  const { data } = useGetShopSyncSyncedQuery(undefined, { enabled: Boolean(is_visible) });

  useEffect(() => {
    setShopSynced(Boolean(data?.shop_sync?.error));
  }, [data?.shop_sync?.error]);

  if (!is_visible || !has_shop_sync_error) {
    return null;
  }

  return info.solution ? (
    <ShopSyncErrorMessage
      title={SHOP_SOLUTION_TITLES[info.solution] ?? t('알 수 없는 오류로 인해 상품 연동이 중단되었습니다.')}
    />
  ) : null;
};
