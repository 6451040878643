import { BaseText, VStack, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import lottie from 'lottie-web';
import React, { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import animationData from '@/components/entry_requests/lotties/partner-center-loading.json';

export const InsightFeedbackDone = () => {
  const { t } = useTranslation();
  const lottie_ref = useRef<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    const animation = lottie.loadAnimation({
      animationData,
      container: lottie_ref.current!,
      initialSegment: [0, 50],
      autoplay: true,
      loop: false,
    });

    return () => {
      animation.destroy();
    };
  }, []);
  return (
    <VStack alignment='center'>
      <LottieWrapper ref={lottie_ref} />
      <VStack>
        <BaseText kind='Heading_15_SemiBold' alignment='center' color={semantic_colors.content.secondary}>
          {t('만족도 제출 완료')}
        </BaseText>
        <BaseText kind='Body_13_Regular' color={semantic_colors.content.secondary}>
          {t('소중한 의견 감사해요')}
        </BaseText>
      </VStack>
    </VStack>
  );
};

const LottieWrapper = styled.div`
  width: 150px;
  height: 150px;
`;
