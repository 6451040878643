export const isPartnerLoungeUrl = (url: string) => {
  try {
    const url_obj = new URL(url);
    const host = url_obj.host;
    const match_url_list = ['partnerlounge.kakaostyle.com'];
    return match_url_list.some((match_url) => host.includes(match_url));
  } catch (error) {
    return false;
  }
};
