import { IconCircleInfo, TextButton } from '@croquiscom/pds';
import React, { ButtonHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { HelpConstantOptions, useHelpConstants } from '@/hooks/useHelpConstants';
import { PartnersBridge } from '@/utils/app_utils';
import { isPartnerLoungeUrl } from '@/utils/isPartnerLoungeUrl';

export interface GuideConstantButtonProps<T extends Record<string, string>>
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  options: HelpConstantOptions<T>;
  linkKey: keyof T;
}

export function GuideConstantButton<T extends Record<string, string>>(
  props: GuideConstantButtonProps<T>,
): React.ReactElement {
  const { options, linkKey, ...restProps } = props;
  const { data } = useHelpConstants(options);
  const { t } = useTranslation('common');
  const src = data[linkKey];

  return (
    <TextButton
      kind='link'
      {...restProps}
      startIcon={<IconCircleInfo width={16} height={16} />}
      onClick={(e) => {
        if (src == null || src === '') {
          e.preventDefault();
          alert(t('준비중입니다. 나중에 다시 확인해주세요.'));
          return;
        }

        if (isPartnerLoungeUrl(src)) {
          PartnersBridge.callInAppBrowserDeepLink(src);
        } else {
          window.open(src, '_blank', 'noopener');
        }
      }}
    />
  );
}
