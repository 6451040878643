/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import {
  BaseText,
  IconArrowChevronRight,
  semantic_colors,
  Badge,
  IconArrowIncrease,
  IconArrowDecrease,
} from '@croquiscom/pds';
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';
import { useGetAdGradeInfo } from '@/components/advertisement/hooks/shop';
import { useShopPrefix } from '@/hooks/useShopPrefix';

export const PredictedAdGrade: React.FC = () => {
  const prefix = useShopPrefix();
  const { predicted_grade, diff } = useGetAdGradeInfo();

  if (!predicted_grade) {
    return null;
  }

  return (
    <div>
      <BaseText
        css={css`
          margin: 8px 0 4px;
        `}
        kind='Body_12_SemiBold'
        color={semantic_colors.content.secondary}
      >
        다음달 예상 등급
      </BaseText>
      <PredictedGradeContainer
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
        to={`${prefix}/notice/1927`}
      >
        <PredictedGrade
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <BaseText
            css={css`
              margin-right: 2px;
            `}
            kind='Body_12_SemiBold'
            color={semantic_colors.content.primary}
          >
            {predicted_grade}
          </BaseText>
          {diff !== 0 && (
            <Badge color={diff > 0 ? 'red' : 'blue'} kind='fill' size='medium'>
              {diff > 0 && (
                <>
                  상승 <IconArrowIncrease size={8} color={semantic_colors.state.negative} />
                </>
              )}
              {diff < 0 && (
                <>
                  하락 <IconArrowDecrease size={8} color={semantic_colors.accent.primary} />
                </>
              )}
            </Badge>
          )}
        </PredictedGrade>
        <IconArrowChevronRight size={14} color={semantic_colors.content.secondary} />
      </PredictedGradeContainer>
      <BaseText kind='Caption_10_SemiBold' color={semantic_colors.content.tertiary}>
        * 어제까지 누적 금액 기준 (변동 가능)
      </BaseText>
    </div>
  );
};

const PredictedGradeContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PredictedGrade = styled.div`
  display: flex;
  align-items: center;
`;
