import { Divider, HStack, IconCircleInfo, TextButton } from '@croquiscom/pds';
import React from 'react';
import { Trans } from 'react-i18next';
import { InsightGuideMenuButton } from './InsightGuideMenuButton';

interface InsightDescriptionProps {
  menu_type: 'starter' | 'sales' | 'competitor' | 'keyword' | 'customer';
  screen_link: string;
  data_link?: string;
}

const InsightDescription: React.FC<InsightDescriptionProps> = ({ menu_type, screen_link }) => {
  return (
    <HStack alignment='center'>
      <InsightGuideMenuButton menu_types={menu_type} />
      <Divider direction='vertical' spacing={8} style={{ height: 12 }} />
      <a href={screen_link} target='_blank' rel='noreferrer'>
        <TextButton kind='link' size='large' startIcon={<IconCircleInfo />}>
          <Trans ns='insight'>화면 사용법</Trans>
        </TextButton>
      </a>
      {/*
      //TODO: 데이터 링크 삽입 예정
      <Divider direction='vertical' spacing={8} style={{ height: 12 }} />
      <a href={data_link} target='_blank' rel='noreferrer'>
        <TextButton kind='link' startIcon={<IconCircleInfo />}>
          <BaseText kind='Body_14_Regular'>
            <Trans ns='insight'>데이터 활용법</Trans>
          </BaseText>
        </TextButton>
      </a> */}
    </HStack>
  );
};

export default InsightDescription;
