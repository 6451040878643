import { colors, text_styles } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { useGetAdDisplayInfoQuery } from '../hooks/useGetAdDisplayInfoQuery';
import { useShopPrefix } from '@/hooks/useShopPrefix';

export interface AdDisplayRegistrationCheckerProps {
  children: ReactNode;
}

export const AdDisplayRegistrationChecker: FC<AdDisplayRegistrationCheckerProps> = ({ children }) => {
  const prefix = useShopPrefix();
  const { data: adDisplayInfo, error, isLoading } = useGetAdDisplayInfoQuery();
  const isError = error !== null || adDisplayInfo?.error;
  const isBeforeAgreeProvision = adDisplayInfo?.agreementDate == null;

  if (isLoading) {
    return (
      <StyledMessage>
        <StyledDescription>광고 정보를 불러 오는 중입니다.</StyledDescription>
      </StyledMessage>
    );
  }

  if (isError) {
    return (
      <StyledMessage>
        <StyledTitle>현재 광고서비스의 원할한 사용이 불가능 합니다.</StyledTitle>
        <StyledDescription>
          이 페이지가 계속 표시된다면 <br />
          지그재그로 문의해주세요. <br /> (02-1670-8051)
        </StyledDescription>
      </StyledMessage>
    );
  }

  if (isBeforeAgreeProvision) {
    return <Redirect to={`${prefix}/ad_display/registration`} />;
  }

  return <>{children}</>;
};

const StyledMessage = styled.div`
  margin-top: 70px;
  text-align: center;
  min-height: 250px;
`;

const StyledTitle = styled.h1`
  ${text_styles.Heading_20_Bold};
  text-align: center;
  color: ${colors.gray900};
`;

const StyledDescription = styled.p`
  margin-top: 12px;
  ${text_styles.Body_14_Regular};
  text-align: center;
  color: ${colors.gray700};
`;
