import { semantic_colors } from '@croquiscom/pds';
import { BadgeColor } from '@croquiscom/pds/dist/components/badge/types';
import React from 'react';
import { Trans } from 'react-i18next';
import { PromotionPriceStatus, PromotionPriceType, PromotionDiscountPriceCreationType } from '@/api';
import { SiteId, SITE_NAME_I18N } from '@/constants/site';

export const PROMOTION_PRICE_SITE_LIST = [
  {
    label: SITE_NAME_I18N[SiteId.지그재그]?.(),
    value: SiteId.지그재그,
  },
  {
    label: SITE_NAME_I18N[SiteId.포스티]?.(),
    value: SiteId.포스티,
  },
  {
    label: SITE_NAME_I18N[SiteId.패션_바이_카카오]?.(),
    value: SiteId.패션_바이_카카오,
  },
];

export const PROMOTION_PRICE_STATUS_BADGE: Record<
  PromotionPriceStatus,
  {
    title: React.ReactNode;
    color: BadgeColor;
  }
> = {
  [PromotionPriceStatus.END]: {
    title: '종료',
    color: 'gray',
  },
  [PromotionPriceStatus.PENDING]: {
    title: '진행 예정',
    color: 'green',
  },
  [PromotionPriceStatus.RUNNING]: {
    title: '진행중',
    color: 'blue',
  },
};

export const PROMOTION_PRICE_TYPE: Record<
  PromotionPriceType,
  {
    title: string;
  }
> = {
  [PromotionPriceType.EXHIBITION]: {
    title: '기획전',
  },
  [PromotionPriceType.GENERAL]: {
    title: '일반',
  },
  [PromotionPriceType.ZIGZIN_OUTLET]: {
    title: '직진아울렛',
  },
};

export const PROMOTION_PRICE_CREATE_TYPE: Record<
  PromotionDiscountPriceCreationType,
  {
    title: string;
  }
> = {
  [PromotionDiscountPriceCreationType.AUTO]: {
    title: '상품 연동 등록',
  },
  [PromotionDiscountPriceCreationType.MANUAL]: {
    title: '직접 등록',
  },
};

export const PROMOTION_PRICE_DESCRIPTION = (
  <Trans
    ns='promotion'
    i18nKey='- 할인 판매가는 판매가를 할인한 최종 금액으로, 기간 내 해당 금액으로 등록, 판매할 수 있는
    페이지입니다.<br />- 상품, 옵션별 할인 판매가와 할인 기간을 등록할 수 있습니다.'
  />
);

export const PROMOTION_PRICE_HISTORY_DESCRIPTION = [
  {
    color: semantic_colors.content.secondary,
    text: (
      <Trans
        ns='promotion'
        i18nKey='- 상품 단위로 할인 판매가를 설정할 경우, 옵션별 차액이 있는 상품은 상품, 옵션 할인 판매가가 생성됩니다.'
      />
    ),
  },
  {
    color: semantic_colors.accent.primary,
    text: (
      <Trans
        ns='promotion'
        i18nKey='- 할인 판매가 중지시, 대상 상품에 존재하는 상품, 옵션 할인 판매가를 모두 중지해주셔야 일반 판매가로 판매됩니다.'
      />
    ),
  },
  {
    color: semantic_colors.content.secondary,
    text: (
      <Trans
        ns='promotion'
        i18nKey='- 동일 상품 또는 옵션에 할인 판매가를 등록한 경우, 가장 낮은 가격이 최종 판매 가격으로 노출됩니다.'
      />
    ),
  },
  {
    color: semantic_colors.content.secondary,
    text: (
      <Trans
        ns='promotion'
        i18nKey='- 등록 시점으로 부터 2개월 초과된 할인 판매가는 미노출 됩니다. 지그재그로 문의해주세요.'
      />
    ),
  },
];

export const PROMOTION_PRICE_APPLY_DESCRIPTION = (
  <Trans
    ns='promotion'
    i18nKey='- 상품 단위로 할인 판매가를 설정할 경우, 옵션별 차액을 반영하여 할인 판매가가 생성됩니다. (상품 판매가 기준 할인 금액 계산하여 차감)<br/>
    - 옵션 단위로 각각 다른 할인 판매가를 적용하고 싶으실 경우에만 `옵션 단위 설정`을 등록해주세요.'
  />
);

export const PROMOTION_PRICE_CREATE_PERIOD_DESCRIPTION = (
  <Trans
    ns='promotion'
    i18nKey='- 할인 판매가의 적용 기간을 설정할 수 있습니다.<br/>
    - 한국 날짜 기준으로 입력해주세요.<br/>'
  />
);
