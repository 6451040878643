import { PageHeader, SectionTitle } from '@croquiscom/pds';
import React, { lazy } from 'react';
import { RoutesType } from '../types';
import { SellerResource } from '@/api';
import { OrderLinkedListUrl } from 'rui/components/URL';

const OrderLinkedListQuestionPage = lazy(() => import('@/pages/order_linked_management/list/question'));
const OrderLinkedListQuestionDetailPage = lazy(() => import('@/pages/order_linked_management/list/question/detail'));
const OrderLinkedListSearchPage = lazy(() => import('@/pages/order_linked_management/list/search'));
const OrderLinkedListShipmentPage = lazy(() => import('@/pages/order_linked_management/list/shipment'));
const OrderLinkedListReturnPage = lazy(() => import('@/pages/order_linked_management/list/return'));
const OrderLinkedListCancelPage = lazy(() => import('@/pages/order_linked_management/list/cancel'));
const OrderLinkedListExchangePage = lazy(() => import('@/pages/order_linked_management/list/exchange'));
const OrderLinkedListECSettlementPage = lazy(() => import('@/pages/order_linked_management/list/ec_settlement'));
const OrderLinkedListECTaxInvoicePage = lazy(() => import('@/pages/order_linked_management/list/ec_tax_invoice'));
const OrderLinkedListECVatReferencePage = lazy(() => import('@/pages/order_linked_management/list/ec_vat_reference'));

export const ORDER_LINKED_MANAGEMENT_ROUTES: RoutesType[] = [
  {
    current_menu: 'order_linked_management_list_question',
    path: OrderLinkedListUrl.QUESTION,
    params: '',
    component: <OrderLinkedListQuestionPage />,
    res: [SellerResource.OMS_QNA_MANAGEMENT],
  },
  {
    current_menu: 'order_linked_management_list_question',
    path: OrderLinkedListUrl.QUESTION,
    params: '/:id',
    component: <OrderLinkedListQuestionDetailPage />,
    res: [SellerResource.OMS_QNA_MANAGEMENT],
  },
  {
    current_menu: 'order_linked_management_list_search',
    path: OrderLinkedListUrl.SEARCH,
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='연동 주문 전체 검색' />
        </PageHeader>
        <OrderLinkedListSearchPage />
      </>
    ),
    res: [SellerResource.OMS_ORDER_MANAGEMENT],
  },
  {
    current_menu: 'order_linked_management_list_shipment',
    path: OrderLinkedListUrl.SHIPMENT,
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='연동 주문 배송 관리' />
        </PageHeader>
        <OrderLinkedListShipmentPage />
      </>
    ),
    res: [SellerResource.OMS_ORDER_MANAGEMENT],
  },
  {
    current_menu: 'order_linked_management_list_return',
    path: OrderLinkedListUrl.RETURN,
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='연동 주문 반품 관리' />
        </PageHeader>
        <OrderLinkedListReturnPage />
      </>
    ),
    res: [SellerResource.OMS_ORDER_MANAGEMENT],
  },
  {
    current_menu: 'order_linked_management_list_cancel',
    path: OrderLinkedListUrl.CANCEL,
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='연동 주문 취소 관리' />
        </PageHeader>
        <OrderLinkedListCancelPage />
      </>
    ),
    res: [SellerResource.OMS_ORDER_MANAGEMENT],
  },
  {
    current_menu: 'order_linked_management_list_exchange',
    path: OrderLinkedListUrl.EXCHANGE,
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='연동 주문 교환 관리' />
        </PageHeader>
        <OrderLinkedListExchangePage />
      </>
    ),
    res: [SellerResource.OMS_ORDER_MANAGEMENT],
  },
  {
    current_menu: 'order_linked_management_list_ec_settlement',
    path: OrderLinkedListUrl.EC_SETTLEMENT,
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='정산 관리' />
        </PageHeader>
        <OrderLinkedListECSettlementPage />
      </>
    ),
    res: [SellerResource.OMS_SETTLEMENT_MANAGEMENT],
  },
  {
    current_menu: 'order_linked_management_list_ec_tax_invoice',
    path: OrderLinkedListUrl.EC_TAX_INVOICE,
    params: '',
    component: (
      <>
        <OrderLinkedListECTaxInvoicePage />
      </>
    ),
    res: [SellerResource.ALL],
  },
  {
    current_menu: 'order_linked_management_list_ec_vat_reference',
    path: OrderLinkedListUrl.EC_VAT_REFERENCE,
    params: '',
    component: <OrderLinkedListECVatReferencePage />,
    res: [SellerResource.ALL],
  },
];
