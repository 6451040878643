import { ExcelUploadTabType } from '../excel_upload/constants';
import { CatalogExcelImportType } from '@/api';
import { Config } from '@/config';

export const CATALOG_ITEM_LIST_URL = '/catalog/item_list';
export const CATALOG_ITEM_DETAIL_URL = '/catalog/item_detail';
export const CATALOG_ITEM_DETAIL_NEW_URL = '/catalog/item_detail/new';
export const CATALOG_ITEM_DETAIL_CRAWLING_URL = '/catalog/item_detail/crawling';

export const EXCEL_UPLOAD_URL = '/excel_upload';
export const CATALOG_EXCEL_UPLOAD_FAIL_URL = '/excel_upload/catalog/fail';
export const ZPAY_DISCOUNT_EXCEL_UPLOAD_FAIL_URL = '/excel_upload/zpay_discount/fail';
export const SHIPPING_EXCEL_UPLOAD_FAIL_URL = '/excel_upload/shipping/fail';

export const CATALOG_NOTICE_URL = '/catalog/notice';
export const IMPORT_EXTERNAL_CATALOG_URL = '/catalog/import_external_catalog';
export const EXCLUSIVE_CATALOG_APPLY = '/catalog/exclusive_catalog_apply';

export function getCatalogItemDetailURL(id: string) {
  return `${CATALOG_ITEM_DETAIL_URL}/${id}`;
}

export function getCatalogItemDetailCrawlingURL(id: string) {
  return `${CATALOG_ITEM_DETAIL_CRAWLING_URL}/${id}`;
}

export function getCatalogExcelUploadURL(type: ExcelUploadTabType) {
  return `${EXCEL_UPLOAD_URL}?tab=${type}`;
}

export function getCatalogExcelUploadFailURL(type: ExcelUploadTabType, id?: string) {
  return `/excel_upload/${type}/fail${id ? `/${id}` : ''}`;
}

export function getCatalogExcelUploadFailureByImportTypeURL(
  tab_type: ExcelUploadTabType,
  type: CatalogExcelImportType,
  id?: string,
) {
  return `/excel_upload/${tab_type}/${type}/fail${id ? `/${id}` : ''}`;
}

export function getPromotionPriceNoticeURL() {
  return `https://partners.kakaostyle.com/shop/${Config.shop_main_domain}/notice/3142`;
}
