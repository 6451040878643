import {
  BaseText,
  VStack,
  semantic_colors,
  Modal,
  Input,
  SectionTitle,
  DataTable,
  DataTableColumnsType,
  DataTableRowType,
  HStack,
  Button,
  FormHelperText,
  Alert,
  Confirm,
} from '@croquiscom/pds';
import { checkMobileTelNumber, getTelNumberWithHypen } from '@croquiscom/zigzag-common';
import styled from '@emotion/styled';
import React, { useMemo, useState } from 'react';
import { FormProvider, useForm, Controller, useFieldArray } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import useMediaQuery from '@/hooks/useMediaQuery';
import {
  useGetNotificationAvailableListQuery,
  useUpdateManagerContactMutation,
  useUpdateNotificationReceiverMutation,
} from 'api';

interface Props {
  opened: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

interface RowType {
  key: string;
  index: number;
  name: string;
  receiver_value: string;
  receiver_key_type: string;
  contact: React.ReactNode | string;
}

const NotificationReceiverModal: React.FC<Props> = ({ opened, onCancel, onConfirm }) => {
  const { t } = useTranslation('main_frame');
  const [selected_row, setSelectedRow] = useState<(string | number | bigint)[]>([]);
  const { data, refetch } = useGetNotificationAvailableListQuery(undefined, {
    enabled: opened,
    onError() {},
    onSuccess(list_data) {
      const list = list_data?.getNotificationAvailableList || [];
      const key_list = list.map((item, index) => (item.agreed ? item.name + index : undefined));
      const filtered_list = key_list.filter((item) => typeof item === 'string') as string[];
      setSelectedRow(filtered_list);
    },
  });
  const notification_receiver_list = data?.getNotificationAvailableList || [];
  const methods = useForm({
    mode: 'onSubmit',
  });
  const { is_desktop } = useMediaQuery();
  const { mutate: updateContact } = useUpdateManagerContactMutation({
    onSuccess() {
      refetch();
    },
  });
  const { mutate } = useUpdateNotificationReceiverMutation({
    onSuccess() {
      refetch();
    },
  });
  useFieldArray({ control: methods.control, name: 'contact' });

  const COlUMNS: DataTableColumnsType<DataTableRowType<RowType>> = [
    {
      id: 'name',
      text: t('이름'),
      width: 70,
    },
    {
      id: 'contact',
      text: t('연락처'),
      cell: ({ row: { index, key, receiver_value }, value: cell_value }) =>
        cell_value || (
          <Controller
            name={`contact[${index}]`}
            control={methods.control}
            rules={{
              validate: (validate_value) => {
                if (selected_row.includes(key) && !validate_value) {
                  return t('데일리 리포트를 수신 설정을 위해 휴대폰 번호를 입력해주세요.');
                } else if (selected_row.includes(key)) {
                  return t('데일리 리포트를 수신 설정을 위해 휴대폰 번호를 저장해주세요.');
                }
              },
            }}
            render={({ field: { value, onChange }, formState: { errors } }) => (
              <VStack>
                <HStack spacing={4}>
                  <Input
                    size='medium'
                    placeholder={t('연락처')}
                    value={getTelNumberWithHypen(value || '')}
                    onChange={onChange}
                    status={errors?.contact?.[index] ? 'error' : undefined}
                  />
                  <Button
                    size='small'
                    onClick={() => {
                      if (!value || checkMobileTelNumber(value)) {
                        return Alert({
                          title: value ? checkMobileTelNumber(value) : t('연락처를 입력해주세요.'),
                        });
                      }
                      updateContact({ manager_id: receiver_value, tel: getTelNumberWithHypen(value || '') });
                    }}
                  >
                    {t('저장')}
                  </Button>
                </HStack>
                <FormHelperText status='error'>{errors?.contact?.[index]?.message}</FormHelperText>
              </VStack>
            )}
          />
        ),
    },
  ];
  const rows = useMemo(
    () =>
      notification_receiver_list.map((item, index) => ({
        index,
        name: item.receiver_key_type === 'CONTACT' ? '스토어 담당자' : item.name,
        contact: item.contact,
        key: item.name + index,
        receiver_key_type: item.receiver_key_type,
        receiver_value: item.receiver_value,
      })),
    [data],
  );
  const onSubmit = () => {
    Confirm({
      title: t('수신자 관리를 완료하겠습니까?'),
      text: t('선택한 수신자에게 내일부터 데일리 리포트가 발행됩니다.'),
      onConfirm: () => {
        methods.reset();
        mutate({
          input: {
            notification_type: 'DAILY_REPORT',
            notification_channel: 'ALIM_TALK',
            receivers: rows.map((item) => ({
              receiver_key_type: item.receiver_key_type,
              receiver_value: item.receiver_value,
              agreed: selected_row.includes(item.key),
            })),
          },
        });
        onConfirm();
      },
    });
  };

  return (
    <Modal
      fullScreen={!is_desktop}
      title={t('데일리 리포트 수신자 관리')}
      confirmText={t('확인')}
      opened={opened}
      contentProps={{ scrollable: true }}
      onCancel={() => {
        Confirm({
          title: t('화면을 닫겠습니까?'),
          text: t('변경하신 내용이 저장되지 않습니다.'),
          onConfirm() {
            methods.reset();
            onCancel();
          },
        });
      }}
      onConfirm={methods.handleSubmit(onSubmit)}
    >
      <FormProvider {...methods}>
        <VStack spacing={24}>
          <VStack>
            <BaseText color={semantic_colors.content.secondary}>
              <Trans t={t}>
                수신자를 추가하시면 매일 오전 데일리 리포트를 알림톡으로 편리하게 받을 수 있습니다. 스토어 매니저로
                등록된 계정 중 수신자를 선택하세요.
              </Trans>
            </BaseText>
            <List>
              <li>
                <BaseText color={semantic_colors.content.secondary}>
                  스토어 매니저는 "내 스토어 정보 관리 &gt; 매니저 계정/권한 관리" 에서 추가할 수 있습니다.
                </BaseText>
              </li>
              <li>
                <BaseText color={semantic_colors.content.secondary}>
                  매니저 계정의 연락처 수정은 해당 계정으로 로그인 후 '파트너센터 상단&gt;계정정보수정' 메뉴에서
                  가능합니다.
                </BaseText>
              </li>
            </List>
          </VStack>
          <VStack spacing={8}>
            <SectionTitle title={t('수신자 목록')} />
            <DataTable
              rowSelection={selected_row}
              selectableRows
              onSelectRows={(rows) => setSelectedRow(rows)}
              columns={COlUMNS}
              rows={rows}
            />
          </VStack>
        </VStack>
      </FormProvider>
    </Modal>
  );
};

export default NotificationReceiverModal;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  font-size: 8px;
  list-style: disc;
  list-style-position: outside;
  color: ${semantic_colors.content.secondary};
`;
