import { SectionTitle, Button } from '@croquiscom/pds';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useShopPrefix } from '@/hooks/useShopPrefix';

const CatalogNoticeMainSectionTitle = () => {
  const history = useHistory();
  const shop_prefix = useShopPrefix();
  const { t } = useTranslation();

  return (
    <SectionTitle
      title='상품 공지사항 관리'
      description={
        <Trans t={t} ns='catalog'>
          상품 상세 설명 상단에 1개의 공지만 우선순위에 따라 게시됩니다.
          <br />
          • 공지 설정 중복 시 최근 등록순 게시
          <br />
          • 특정 기간(택배 파업, 명절 등)을 설정한 공지는 해당 기간 내에서 최우선 게시
          <br />
          • 휴가 공지는 최우선 노출, 노출 중에만 배송 휴무일 반영 (모든 상품의 상세 페이지에 노출)
          <br />
          공지 등록 시 직진배송 상품에는 지원되지 않습니다.
        </Trans>
      }
      actions={
        <Button kind='primary' onClick={() => history.push(`${shop_prefix}/catalog/notice/new`)}>
          {t('상품 공지사항 등록')}
        </Button>
      }
    />
  );
};

export default CatalogNoticeMainSectionTitle;
