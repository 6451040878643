import { Badge, HStack, Tooltip } from '@croquiscom/pds';
import styled from '@emotion/styled';
import { useUpdateAtom } from 'jotai/utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { drawer_menu_open_atom } from '../atom';
import { useScrollDebounce } from '../hooks/useScrollDebounce';
import { ItemRow } from './ItemRow';
import { useGetPlan } from '@/components/insight/hooks/useGetPlan';
import { useGetPartnerPlusGnbTooltipText } from '@/components/partner_plus/hooks/useGetPartnerPlusGnbTooltipText';
import { Config } from '@/config';
import { SiteId } from '@/constants/site';
import { useAccount } from '@/hooks/useAccount';
import { useInfo } from '@/hooks/useInfo';
import useMediaQuery from '@/hooks/useMediaQuery';
import { useOnceTooltip } from '@/hooks/useOnceTooltip';
import { usePbl } from '@/hooks/usePbl';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { PartnerPlusNavUrl } from 'rui/components/URL';

const TOOLTIP_RESENT_ID = `PartnerPlusGnb.${Config.shop_main_domain}.Tooltip`;
const TOOLTIP_HAS_COUPON = `PartnerPlusGnb.${Config.shop_main_domain}.TooltipHasCoupon`;

export interface ShopInfoInsightProps {}

export const ShopInfoInsight: React.FC<ShopInfoInsightProps> = () => {
  const shop_prefix = useShopPrefix();
  const { t } = useTranslation('main_frame');
  const { info } = useInfo();
  const { is_zigzag } = useAccount();
  const is_shown = is_zigzag || info.site?.id === SiteId.지그재그;
  const { pbl } = usePbl();

  const { is_partner_plus } = useGetPlan();
  const { data, isLoading } = useGetPartnerPlusGnbTooltipText(is_shown);
  const [tooltip_id, setTooltipId] = useState(localStorage.getItem(TOOLTIP_RESENT_ID));
  const no_subscription_text = t('첫 달 구독료 100원!');
  const { is_show, hideTooltip } = useOnceTooltip({
    key: TOOLTIP_HAS_COUPON,
    compare_value: no_subscription_text,
  });
  const is_show_scroll = useScrollDebounce(is_show || data?.id !== tooltip_id);
  const { is_desktop } = useMediaQuery();
  const onDrawerOpen = useUpdateAtom(drawer_menu_open_atom);

  const hideTooltipWithId = () => {
    if (data?.id) {
      setTooltipId(data.id);
      localStorage.setItem(TOOLTIP_RESENT_ID, data.id);
    }
  };

  const PartnerSubscriptionContent = is_partner_plus ? (
    <div>
      <ItemRow
        title={
          <HStack spacing={4} alignment='center'>
            {t('파트너플러스')}
            <Badge color={'green'} size='small' kind='fill'>
              {t('구독중')}
            </Badge>
          </HStack>
        }
        show_right
        to={`${shop_prefix}${PartnerPlusNavUrl.MANAGEMENT_BENEFIT}`}
        onClick={() => {
          pbl({
            category: 'click',
            navigation: 'gnb_n_top_bar',
            object_id: 'pages',
            object_section: 'navigations',
            extra: JSON.stringify({
              item: '파트너플러스 구독중',
            }),
          });
          hideTooltipWithId();
        }}
      />
    </div>
  ) : (
    <></>
  );
  if (!is_shown) {
    return null;
  }
  if (is_partner_plus) {
    if (!isLoading && data?.id) {
      return (
        <Tooltip
          opened={tooltip_id !== data.id && is_show_scroll}
          content={<TextBox>{data?.contents || ''}</TextBox>}
          openerTriggerEvent='none'
          placement='right'
          kind='primary'
          canClickOutside={false}
          zIndex={90}
          closeButton
          onClose={() => {
            if (tooltip_id !== data.id && is_show_scroll) {
              hideTooltipWithId();
            }
          }}
        >
          {PartnerSubscriptionContent}
        </Tooltip>
      );
    }
    return PartnerSubscriptionContent;
  }
  return (
    <Tooltip
      opened={false}
      content={<TextBox>{no_subscription_text}</TextBox>}
      openerTriggerEvent='none'
      placement='right'
      canClickOutside={false}
      zIndex={90}
      closeButton
      onClose={() => {
        if (is_show && is_show_scroll) {
          hideTooltip();
        }
      }}
    >
      <div>
        <ItemRow
          title={t('파트너플러스 소개')}
          show_right
          to={`${shop_prefix}${PartnerPlusNavUrl.INTRO}`}
          onClick={() => {
            pbl({
              category: 'click',
              navigation: 'gnb_n_top_bar',
              object_id: 'pages',
              object_section: 'navigations',
              extra: JSON.stringify({
                item: '파트너플러스 소개',
              }),
            });
            hideTooltip();

            if (!is_desktop) {
              onDrawerOpen(false);
            }
          }}
        />
      </div>
    </Tooltip>
  );
};

const TextBox = styled.div`
  max-width: 200px;
  word-break: keep-all;
`;
