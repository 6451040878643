import { PageHeader, SectionTitle } from '@croquiscom/pds';
import React, { lazy } from 'react';
import { RoutesType } from '../types';
import { SellerResource } from 'api';

const SellerSupportProgramMainPage = lazy(() => import('@/pages/seller_support_program/MainPage'));
const CustomerExtensionPage = lazy(() => import('@/pages/seller_support_program/CustomerExtensionPage'));
const CustomerExtensionIntroPage = lazy(() => import('@/pages/seller_support_program/CustomerExtensionIntroPage'));

export const SELLER_SUPPORT_PROGRAM_ROUTES: RoutesType[] = [
  {
    current_menu: 'seller_support_program',
    path: '/seller_support_program',
    params: '',
    component: (
      <>
        <PageHeader border={false}>
          <SectionTitle
            title='중소형 성장 지원 프로그램'
            description='스토어 맞춤형 프로그램을 통해 지속적인 매출 성장을 경험해보세요.'
          />
        </PageHeader>
        <SellerSupportProgramMainPage />
      </>
    ),
    res: [SellerResource.ALL],
  },
  {
    current_menu: 'customer_extension',
    path: '/seller_support_program/customer_extension',
    params: '',
    component: (
      <>
        <CustomerExtensionPage />
      </>
    ),
  },
  {
    current_menu: 'customer_extension_intro',
    path: '/seller_support_program/customer_extension/intro',
    params: '',
    component: (
      <>
        <CustomerExtensionIntroPage />
      </>
    ),
  },
];
