import { colors, IconX, IconCounselor, IconChecklist, IconMessage } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { RoundedButton } from './RoundedButton';
import { useZendesk } from '@/app/rui/components/main_frame/floating/hooks/useZendesk';
import { useLocation } from '@/components/route/LegacyRouterCompat';

interface ZendeskButtonProps {
  onClose: () => void;
}

const ZendeskButtons = ({ onClose }: ZendeskButtonProps) => {
  const { open, openLog } = useZendesk();

  const handleChatOpen = () => {
    open();
    onClose();
  };

  const handleLogOpen = () => {
    openLog();
    onClose();
  };

  return (
    <>
      <RoundedButton
        icon={<StyledIconQuestion24 size={16} />}
        border_color={colors.kakao_yellow600}
        background_color={colors.kakao_yellow400}
        onClick={handleChatOpen}
      >
        채팅 상담
      </RoundedButton>
      <RoundedButton
        icon={<StyledIconOrder24 size={16} />}
        border_color={colors.kakao_yellow600}
        background_color={colors.kakao_yellow400}
        onClick={handleLogOpen}
      >
        문의내역 확인
      </RoundedButton>
    </>
  );
};

interface ExternalLinkButtonProps {
  url: string;
}

const ExternalLinkButton = ({ url }: ExternalLinkButtonProps) => {
  const handleExternalLinkOpen = () => {
    window.open(url, '_blank', 'noopener');
  };

  return (
    <CircleButton type='button' onClick={handleExternalLinkOpen}>
      <IconCounselor size={30} />
    </CircleButton>
  );
};

const EXTERNAL_LINK: Array<{ start_path: string; url: string }> = [];

export const CsCenterButtons = () => {
  const { pathname } = useLocation();
  const external_link_url = EXTERNAL_LINK.find(({ start_path }) => pathname.startsWith(start_path))?.url;
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(false);
  }, [external_link_url]);

  const handleToggle = () => {
    setOpened((prev) => !prev);
  };

  const handleClose = () => {
    setOpened(false);
  };

  return (
    <>
      {opened && <ZendeskButtons onClose={handleClose} />}
      {external_link_url ? (
        <ExternalLinkButton url={external_link_url} />
      ) : (
        <CircleButton type='button' onClick={handleToggle}>
          {opened ? <IconX size={24} /> : <IconCounselor size={30} />}
        </CircleButton>
      )}
    </>
  );
};

const CircleButton = styled.button`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: 0;
  border-radius: 25px;
  background-color: ${colors.kakao_yellow400};
  cursor: pointer;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
`;

const StyledIconQuestion24 = styled(IconMessage)`
  margin-right: 6px;
`;

const StyledIconOrder24 = styled(IconChecklist)`
  margin-right: 6px;
`;
