import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router'; // LegacyCompat 변환 X

import { CATALOG_ITEM_LIST_URL, PRODUCT_REVIEW_LIST_URL } from 'rui/components/URL';

export function ScrollToTop(): null {
  const location = useLocation();
  const isMountedRef = useRef<boolean>(false);

  useEffect(() => {
    // 첫 마운트 이후에만 실행
    if (isMountedRef.current) {
      const { pathname } = location;
      const shouldScrollToTop = ![
        '/ad/zigzag',
        '/ad/3.0/reports',
        '/attention/main',
        '/attention/detail',
        '/advertisement',
        '/fbk_ads/ad_set',
        '/ad_display/management_two',
        '/wallet',
        '/shipping_penalty',
        CATALOG_ITEM_LIST_URL,
        PRODUCT_REVIEW_LIST_URL,
      ].some((path) => pathname.includes(path));

      if (shouldScrollToTop) {
        window.scrollTo({ top: 0 });
      }
    }
    isMountedRef.current = true;
  }, [location]);

  return null;
}
