import { Banner, VStack, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { ShopInfoAdGrade } from './ShopInfoAdGrade';
import { ShopInfoCustomerExtension } from './ShopInfoCustomerExtension';
import { ShopInfoDailyReport } from './ShopInfoDailyReport';
import { ShopInfoInsight } from './ShopInfoInsight';
import { ShopInfoLinkInfo } from './ShopInfoLinkInfo';
import { ShopInfoProfile } from './ShopInfoProfile';
import { ShopInfoZCoin } from './ShopInfoZCoin';
import { isApp } from '@/utils/app_utils/conditions';

export interface ShopInfoProps {}

export const ShopInfo: React.FC<ShopInfoProps> = React.memo(() => {
  return (
    <StyledContainer>
      <ShopInfoProfile />
      <ShopInfoLinkInfo />
      {isApp() && (
        <VStack mb={16}>
          <Banner
            kind='info'
            size='small'
            showIcon={false}
            message='현재 파트너센터 앱은 일부 기능만 사용할 수 있어요. 모든 기능을 사용하려면 PC를 이용해주세요.'
          />
        </VStack>
      )}
      <StyledPanel>
        {!isApp() && <ShopInfoZCoin />}
        <ShopInfoAdGrade />
      </StyledPanel>
      <StyledDivider />
      <StyledPanel>
        {!isApp() && (
          <>
            <ShopInfoInsight />
            <ShopInfoCustomerExtension />
          </>
        )}
        <ShopInfoDailyReport />
      </StyledPanel>
    </StyledContainer>
  );
});

const StyledContainer = styled.div`
  padding: 24px 20px 16px;
  background-color: ${semantic_colors.background.surface};
  border-bottom: 8px solid ${semantic_colors.background.base};
`;

const StyledPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledDivider = styled.hr`
  display: block;
  margin: 16px 0;
  height: 1px;
  border: none;
  background-color: ${semantic_colors.border.default};
`;
