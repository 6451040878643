import { PageHeader, SectionTitle } from '@croquiscom/pds';
import React, { lazy } from 'react';
import { RoutesType } from '../types';
import { SellerResource } from '@/api';
import { AdDisplayRegistrationChecker } from '@/components/advertisement/ad_display/common';
import { AdCreativeFormContext } from '@/components/advertisement/attention/AddAdCreative/AdCreativeFormContext';
import AdPageTitle from '@/components/advertisement/common/AdPageTitle';
import { SiteId } from '@/constants/site';

import { FBK_AD_CONTENTS_INTRODUCTION, ZIGZAG_AD_CONTENTS_INTRODUCTION } from 'rui/constants';
const AdDisplayDetailPage = lazy(() => import('@/pages/advertisement/ad_display/detail'));
const AdDisplayMainPage = lazy(() => import('@/pages/advertisement/ad_display/main'));
const FbkAdsMainPage = lazy(() => import('@/pages/advertisement/ad_fbk/main'));
const AdRegistrationPage = lazy(() => import('@/pages/advertisement/ad_registration'));
const FbkAdBestAd = lazy(() => import('@/components/advertisement/ad_fbk/main/BestAd'));

const BridgePage = lazy(() => import('@/pages/advertisement/bridge'));
const AdExhibitionPage = lazy(() => import('@/pages/advertisement/ad_exhibition'));
const AutoAddCashInfoPage = lazy(() => import('@/pages/advertisement/ad_auto_add_cash'));
const WalletPage = lazy(() => import('@/pages/ad/wallet'));
const FbkAdSetDetailPage = lazy(() => import('@/pages/advertisement/ad_fbk/ad_set'));
const FbkAdDetailPage = lazy(() => import('@/pages/advertisement/ad_fbk/ad'));
const ADSetGroupPage = lazy(() => import('@/pages/advertisement/ad_fbk/ad_set_group'));
const AdNotificationPage = lazy(() => import('@/pages/advertisement/ad_notifications'));
const LegacyReportsBannersPage = lazy(() => import('@/pages/ad/3.0/reports/banners'));
const LegacyReportsStorePage = lazy(() => import('@/pages/ad/3.0/reports/store'));
const PremiumDisplayAdvertisementPage = lazy(() => import('@/pages/advertisement/premium_display'));
const PremiumDisplayAdvertisementIntroPage = lazy(() => import('@/pages/advertisement/premium_display/intro'));
const CreatePremiumDisplayAdvertisementPage = lazy(
  () => import('@/pages/advertisement/premium_display/top_board/create'),
);
const IntroPage = lazy(() => import('@/pages/ad/3.0/intro'));
const ProductsAdSetsPage = lazy(() => import('@/pages/ad/3.0/products/adsets'));
const BannersAdSetsPage = lazy(() => import('@/pages/ad/3.0/banners/adsets'));
const BannersAdsPage = lazy(() => import('@/pages/ad/3.0/banners/ads'));
const StoresAdSetsPage = lazy(() => import('@/pages/ad/3.0/stores/adsets'));
const StoresAdsPage = lazy(() => import('@/pages/ad/3.0/stores/ads'));
const MessagesAdSetsPage = lazy(() => import('@/pages/ad/3.0/messages/adsets'));
const KakaotalkChannelAdPage = lazy(() => import('@/pages/ad/3.0/kakaotalk-channel'));
const AdvertisementReportsMainPage = lazy(() => import('@/pages/ad/3.0/reports'));
const ProductsAdvertisementReportsPage = lazy(() =>
  import('@/pages/ad/3.0/reports/products').then(({ Legacy }) => ({ default: Legacy })),
);
const ProductsAdvertisementReportsDetailPage = lazy(() =>
  import('@/pages/ad/3.0/reports/products/ad').then(({ Legacy }) => ({ default: Legacy })),
);
const ProductsAdvertisementAnalyticsPage = lazy(() => import('@/pages/ad/3.0/reports/products/analytics'));
const ApplyPackagePage = lazy(() => import('@/pages/ad/3.0/packages/[package_id]'));
const AffiliatePage = lazy(() => import('@/pages/ad/affiliate'));
const AffiliateIntroPage = lazy(() => import('@/pages/ad/affiliate/intro'));

export const AD_ROUTES: RoutesType[] = [
  {
    current_menu: 'ad_notifications',
    path: '/ad_display/settings/notification',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='광고 알림 관리' />
        </PageHeader>
        <AdNotificationPage />
      </>
    ),
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'fbk_ads',
    path: '/fbk_ads/ad_set',
    params: '/:id',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='광고 그룹 상세' />
        </PageHeader>
        <FbkAdSetDetailPage />
      </>
    ),
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'fbk_ads',
    path: '/fbk_ads/ad_set/:ad_set_id/ad/:ad_id',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='광고 상품 상세' />
        </PageHeader>
        <FbkAdDetailPage />
      </>
    ),
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'fbk_ads',
    path: '/fbk_ads/add_group/new',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='광고 생성' />
        </PageHeader>
        <ADSetGroupPage />
      </>
    ),
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'fbk_ads',
    path: '/fbk_ads/add_group/edit',
    params: '/:id',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='광고 그룹 수정' />
        </PageHeader>
        <ADSetGroupPage />
      </>
    ),
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'zigzag',
    path: '/ad/zigzag/exhibition/:action(write|update)',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='광고 기획전 설정' />
        </PageHeader>
        <AdExhibitionPage />
      </>
    ),
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'zigzag',
    path: '/ad/zigzag/bridge/edit',
    params: '/:id/:creativeId',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='브릿지 페이지 설정' />
        </PageHeader>
        <AdCreativeFormContext Form={BridgePage} />
      </>
    ),
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'ad_billings',
    path: '/wallet',
    params: '',
    component: <WalletPage />,
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'auto_add_cash_info',
    path: '/auto_add_cash_info',
    params: '',
    component: <AutoAddCashInfoPage />,
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'premium_display',
    path: '/premium_display',
    params: '',
    component: <PremiumDisplayAdvertisementPage />,
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'premium_display',
    path: '/premium_display/intro',
    params: '',
    component: <PremiumDisplayAdvertisementIntroPage />,
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'premium_display',
    path: '/premium_display/top_board/create',
    params: '',
    component: <CreatePremiumDisplayAdvertisementPage />,
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'zigzag',
    path: '/ad/zigzag/intro',
    params: '',
    component: <IntroPage />,
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'zigzag',
    path: '/ad/zigzag/adsets/products/:action(write|copy|update)',
    params: '',
    component: <ProductsAdSetsPage />,
  },
  {
    current_menu: 'zigzag',
    path: '/ad/zigzag/adsets/banners/:action(write|update)',
    params: '',
    component: <BannersAdSetsPage />,
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'zigzag',
    path: '/ad/zigzag/ads/banners/:action(write|copy|update)',
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
    params: '',
    component: <BannersAdsPage />,
  },
  {
    current_menu: 'zigzag',
    path: '/ad/zigzag/adsets/stores/:action(write|update)',
    params: '',
    component: <StoresAdSetsPage />,
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'zigzag',
    path: '/ad/zigzag/ads/stores/:action(write|copy|update)',
    params: '',
    component: <StoresAdsPage />,
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'zigzag',
    path: '/ad/zigzag/adsets/messages/:action(write|copy|update)',
    params: '',
    component: <MessagesAdSetsPage />,
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'zigzag',
    path: '/ad/3.0/kakaotalk-channel/:id?',
    params: '',
    component: <KakaotalkChannelAdPage />,
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'zigzag',
    path: '/ad/3.0/reports',
    params: '',
    component: <AdvertisementReportsMainPage />,
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'zigzag',
    path: '/ad/zigzag/analytics',
    params: '',
    component: <ProductsAdvertisementAnalyticsPage />,
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'zigzag',
    path: '/ad/3.0/reports/products/:id',
    params: '',
    component: <ProductsAdvertisementReportsPage />,
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'zigzag',
    path: '/ad/3.0/reports/products/:ad_set_id/ad/:ad_id',
    params: '',
    component: <ProductsAdvertisementReportsDetailPage />,
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'zigzag',
    path: '/ad/3.0/reports/banners/:id',
    params: '',
    component: <LegacyReportsBannersPage />,
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'zigzag',
    path: '/ad/3.0/reports/store/:id',
    params: '',
    component: <LegacyReportsStorePage />,
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'zigzag',
    path: '/ad/3.0/packages/:package_id',
    params: '',
    component: <ApplyPackagePage />,
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'affiliate',
    path: '/affiliate',
    params: '',
    component: <AffiliatePage />,
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
    is_fullscreen: true,
    is_support_mobile: true,
  },
  {
    current_menu: 'affiliate',
    path: '/affiliate/intro',
    params: '',
    component: <AffiliateIntroPage />,
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
    is_fullscreen: true,
    is_support_mobile: true,
  },
  {
    current_menu: 'fbk_ads',
    path: '/advertisement/fbk_ads',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title={
              <AdPageTitle
                title='패션바이카카오 핫 광고'
                description='패션바이카카오 앱의 영역 중 가치가 높은 지면들에 내 스토어의 상품을 노출하는 광고 상품'
                ad_intro_link={FBK_AD_CONTENTS_INTRODUCTION}
                site_id={SiteId.패션_바이_카카오}
                BestAd={<FbkAdBestAd />}
              />
            }
          />
        </PageHeader>
        <FbkAdsMainPage />
      </>
    ),
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'zigzag_ads',
    path: '/ad_display/management_two',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title={
              <AdPageTitle
                title='파워업 Ai 광고'
                description='Ai 엔진이 고객별 쇼핑 데이터를 기반으로 최적 상품을 자동 선정하고 가치가 높은 앱 내 지면에 노출하는
              고효율 광고 상품'
                ad_intro_link={ZIGZAG_AD_CONTENTS_INTRODUCTION}
                site_id={SiteId.지그재그}
                BestAd={<></>}
                isShowPopularCreative={false}
              />
            }
          />
        </PageHeader>
        <AdDisplayRegistrationChecker>
          <AdDisplayMainPage />
        </AdDisplayRegistrationChecker>
      </>
    ),
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'zigzag_ads',
    path: '/ad_display/contents_two',
    params: '/:selectedAdCampaignId',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title={
              <AdPageTitle
                title='파워업 Ai 광고'
                description='Ai 엔진이 고객별 쇼핑 데이터를 기반으로 최적 상품을 자동 선정하고 가치가 높은 앱 내 지면에 노출하는
              고효율 광고 상품'
                ad_intro_link={ZIGZAG_AD_CONTENTS_INTRODUCTION}
                site_id={SiteId.지그재그}
                BestAd={<></>}
                isShowPopularCreative={false}
              />
            }
          />
        </PageHeader>
        <AdDisplayRegistrationChecker>
          <AdDisplayDetailPage />
        </AdDisplayRegistrationChecker>
      </>
    ),
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
  {
    current_menu: 'zigzag_ads',
    path: '/ad_display/registration',
    params: '',
    component: <AdRegistrationPage />,
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
  },
];
