import { semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';

import { ChannelExtensionSection } from './channel_extension/ChannelExtensionSection';
import { ContactSection } from './contact/ContactSection';
import { MainMenuListContainer } from './menu_list/MainMenuListContainer';
import { MainSubMenuListContainer } from './menu_list/MainSubMenuListContainer';
import { ShopInfo } from './shop_info/ShopInfo';
import AccountBar from '@/app/rui/components/main_frame/accountbar';
import { Floating } from '@/app/rui/components/main_frame/floating/Floating';
import { Footer } from '@/app/rui/components/main_frame/footer/Footer';
import { Gnb } from '@/app/rui/components/main_frame/gnb';
import { Layout } from '@/app/rui/components/main_frame/layout';
import { MetaStoreNoticeSection } from '@/app/rui/components/main_frame/meta_store_notice/MetaStoreNoticeSection';
import { ShopSyncErrorSection } from '@/app/rui/components/main_frame/shop_sync_error/ShopSyncErrorSection';
import useMediaQuery from '@/hooks/useMediaQuery';
import { useOnboarding } from '@/hooks/useOnboarding';
import AppNotSupportedPage from '@/pages/error/AppNotSupportedPage';
import { isApp } from '@/utils/app_utils/conditions';

export interface MainFrameProps {
  current_menu?: string;
  is_support_mobile?: boolean;
  is_support_app?: boolean;
  is_fullscreen?: boolean;
  hide_accountbar?: boolean;
  inactive_content_scroll?: boolean;
  children: React.ReactNode;
}

export const MainFrame: React.FC<MainFrameProps> = ({
  current_menu,
  is_support_mobile,
  is_support_app,
  inactive_content_scroll,
  hide_accountbar = false,
  children,
}) => {
  const { is_desktop } = useMediaQuery();
  useOnboarding();

  const renderChildren = React.useMemo(() => {
    if (isApp() && !is_support_app) {
      return <AppNotSupportedPage />;
    }

    return (
      <>
        {is_desktop && <ShopSyncErrorSection />}
        {children}
        <Footer />
        {is_desktop && <Floating />}
      </>
    );
  }, [is_support_app, is_desktop, children]);

  return (
    <Layout.MainFrame>
      {!hide_accountbar && <AccountBar />}
      <Layout.Container>
        <Gnb>
          <ShopInfo />
          <MainMenuListContainer selected_id={current_menu ?? null} />
          <StyledDivider />
          <MainSubMenuListContainer selected_id={current_menu ?? null} />
          <StyledDivider />
          {!isApp() && <ChannelExtensionSection />}
          <ContactSection />
        </Gnb>
        <Layout.Content inactive_content_scroll={inactive_content_scroll}>
          <Layout.ContentWrap content_min_width={(is_support_mobile && !is_desktop) || isApp() ? '100%' : '1280px'}>
            {renderChildren}
          </Layout.ContentWrap>
        </Layout.Content>
      </Layout.Container>
      <MetaStoreNoticeSection />
    </Layout.MainFrame>
  );
};

const StyledDivider = styled.hr`
  display: block;
  flex: 0 0 auto;
  height: 8px;
  margin: 0;
  padding: 0;
  border: none;
  background-color: ${semantic_colors.background.base};
`;
