import { Button, PageHeader, SectionTitle, TextButton, IconCircleInfo, HStack, Divider } from '@croquiscom/pds';
import React, { lazy } from 'react';
import { SellerResource } from '@/api';
import { GUIDE_COUPON } from '@/components/common/guide/constants';
import { ShopLink } from '@/components/route/ShopLink';
import { RoutesType } from '@/routes/types';

const MyPickCouponEditPage = lazy(() => import('@/pages/coupon/MyPickCouponEditPage'));
const MyPickCouponIntroPage = lazy(() => import('@/pages/coupon/MyPickCouponIntroPage'));
const MyPickCouponListPage = lazy(() => import('@/pages/coupon/MyPickCouponListPage'));
const MyPickCouponReportPage = lazy(() => import('@/pages/coupon/MyPickCouponReportPage'));
const CouponEditPage = lazy(() => import('@/pages/coupon/CouponEditPage'));
const CouponListPage = lazy(() => import('@/pages/coupon/CouponListPage'));
const CouponReportPage = lazy(() => import('@/pages/coupon/CouponReportPage'));
const CouponReportDetailPage = lazy(() => import('@/pages/coupon/CouponReportDetailPage'));
const PointAddPage = lazy(() => import('@/pages/point/PointAddPage'));
const PointListPage = lazy(() => import('@/pages/point/PointListPage'));

export const BENEFIT_ROUTES: RoutesType[] = [
  {
    current_menu: 'promotion_coupon_my_pick_intro',
    path: '/coupon/my_pick/intro',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='마이픽쿠폰 소개' />
        </PageHeader>
        <MyPickCouponIntroPage />
      </>
    ),
  },
  {
    current_menu: 'promotion_coupon_my_pick_new',
    path: '/coupon/my_pick/new',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='마이픽쿠폰 신청' textButton={GUIDE_COUPON.MY_PICK_NEW} />
        </PageHeader>
        <MyPickCouponEditPage />
      </>
    ),
  },
  {
    current_menu: 'promotion_coupon_my_pick_edit',
    path: '/coupon/my_pick/edit',
    params: '/:id',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='마이픽쿠폰 수정' />
        </PageHeader>
        <MyPickCouponEditPage />
      </>
    ),
  },
  {
    current_menu: 'promotion_coupon_my_pick_list',
    path: '/coupon/my_pick/list',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='마이픽쿠폰'
            description='7일간 마이픽쿠폰을 노출하여 잠재고객을 만날 수 있습니다.'
            textButton={
              <TextButton kind='link' startIcon={<IconCircleInfo width={16} height={16} />}>
                <ShopLink to='/coupon/my_pick/intro' style={{ color: 'inherit' }}>
                  마이픽쿠폰 소개
                </ShopLink>
              </TextButton>
            }
            actions={
              <ShopLink to='/coupon/my_pick/new'>
                <Button kind='primary'>마이픽쿠폰 신청</Button>
              </ShopLink>
            }
          />
        </PageHeader>
        <MyPickCouponListPage />
      </>
    ),
  },
  {
    current_menu: 'promotion_coupon_my_pick_detail',
    path: '/coupon/my_pick/list',
    params: '/:id',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='마이픽쿠폰 리포트' />
        </PageHeader>
        <MyPickCouponReportPage />
      </>
    ),
  },
  {
    current_menu: 'coupon_add',
    path: '/coupon/add',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='쿠폰 발행'
            description='발급 대상 타입을 정하여 쿠폰을 발행할 수 있습니다.'
            textButton={
              <HStack spacing={6} alignment='center'>
                <TextButton
                  kind='link'
                  startIcon={<IconCircleInfo width={16} height={16} />}
                  onClick={() =>
                    window.open(
                      'https://docs.google.com/presentation/d/1iCbA_KqCvKsze7L6jKC_Edluovfjb_LkCYbsXiKHiGU/edit?usp=sharing',
                    )
                  }
                >
                  혜택 관리 소개
                </TextButton>
                <Divider direction='vertical' style={{ height: 12 }} />
                <TextButton
                  kind='link'
                  startIcon={<IconCircleInfo width={16} height={16} />}
                  onClick={() => window.open('https://partners.kakaostyle.com/help?id=804', '_blank', 'noopener')}
                >
                  도움말 보기
                </TextButton>
              </HStack>
            }
          />
        </PageHeader>
        <CouponEditPage />
      </>
    ),
    res: SellerResource.COUPON_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'coupon_modify',
    path: '/coupon/modify',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='쿠폰 수정' />
        </PageHeader>
        <CouponEditPage />
      </>
    ),
    res: SellerResource.COUPON_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'promotion_coupon_list',
    path: '/coupon/list',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='쿠폰 조회'
            textButton={
              <TextButton
                kind='link'
                startIcon={<IconCircleInfo width={16} height={16} />}
                onClick={() => window.open('https://partners.kakaostyle.com/help?id=990', '_blank', 'noopener')}
              >
                도움말 보기
              </TextButton>
            }
          />
        </PageHeader>
        <CouponListPage />
      </>
    ),
    res: SellerResource.COUPON_MANAGEMENT_READ,
  },
  {
    current_menu: 'promotion_coupon_report',
    path: '/coupon/report',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='쿠폰 리포트'
            description={'입점한 한국 채널의 모든 데이터를 제공합니다. (글로벌 미지원)'}
            textButton={
              <TextButton
                kind='link'
                startIcon={<IconCircleInfo width={16} height={16} />}
                onClick={() => window.open('https://partners.kakaostyle.com/help?id=805', '_blank', 'noopener')}
              >
                도움말 보기
              </TextButton>
            }
          />
        </PageHeader>
        <CouponReportPage />
      </>
    ),
    res: SellerResource.COUPON_MANAGEMENT_READ,
  },
  {
    current_menu: 'promotion_coupon_report',
    path: '/coupon/report',
    params: '/:id',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='쿠폰 상세 리포트' />
        </PageHeader>
        <CouponReportDetailPage />
      </>
    ),
    res: [SellerResource.COUPON_INSIGHT_READ],
  },
  {
    current_menu: 'promotion_point_add',
    path: '/point/add',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='포인트 지급'
            description='포인트 지급은 지그재그(KR) 채널만 지원합니다.'
            textButton={
              <TextButton
                kind='link'
                startIcon={<IconCircleInfo width={16} height={16} />}
                onClick={() =>
                  window.open(
                    'https://docs.google.com/presentation/d/1iCbA_KqCvKsze7L6jKC_Edluovfjb_LkCYbsXiKHiGU/edit#slide=id.g1f03fde392a_1_67',
                  )
                }
              >
                혜택 관리 소개
              </TextButton>
            }
          />
        </PageHeader>
        <PointAddPage />
      </>
    ),
    res: SellerResource.POINT_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'promotion_point_modify',
    path: '/point/modify',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='포인트 수정' />
        </PageHeader>
        <PointAddPage is_modify={true} />
      </>
    ),
    res: SellerResource.POINT_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'promotion_point_list',
    path: '/point/list',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='포인트 조회' />
        </PageHeader>
        <PointListPage />
      </>
    ),
    res: SellerResource.POINT_MANAGEMENT_READ,
  },
];
