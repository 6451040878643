import { IconLayout } from '@croquiscom/pds';
import { t } from 'i18next';
import React from 'react';
import { checkRes, isAllowedMenuByCountry, isAllowedMenuBySiteId } from '../../utils/menu_list';
import { MenuEntry } from '../types';
import { CreateMenuTreeOptions } from './types';
import { SiteId } from '@/constants/site';
import { ShopType } from '@/types/info';
import { CountryCode, SellerResource } from 'api';
import { VIDEO_LIST_URL } from 'rui/components/URL';

export function createStoreHomeMenuTree({ info }: CreateMenuTreeOptions): MenuEntry[] {
  const is_zpay = info.type === ShopType.ZPAY;
  const is_site_id_allowed = isAllowedMenuBySiteId(info, [
    SiteId.지그재그,
    SiteId.포스티,
    SiteId.도매,
    SiteId.마이스토어,
  ]);
  return [
    {
      id: 'store_home',
      label: t('main_frame::스토어 전시 관리'),
      icon: <IconLayout size={16} color='currentColor' />,
      is_allowed: !info.is_parent && is_zpay,
      is_support_app: true,
      items: [
        {
          id: 'store_home_category',
          label: t('main_frame::카테고리 관리'),
          href: '/store_home/category',
          is_allowed: is_site_id_allowed && info.is_custom_category_allowed === true,
          is_beta: true,
        },
        {
          id: 'store_home_main_banner',
          label: t('main_frame::메인 배너 관리'),
          href: '/store_home/main_banner/list',
          is_allowed: is_site_id_allowed && checkRes([], info.allowed_resource_list),
          is_support_app: true,
        },
        {
          id: 'store_home_collection',
          label: t('main_frame::컬렉션 관리'),
          href: '/store_home/collection/list',
          is_allowed:
            checkRes([], info.allowed_resource_list) &&
            isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]),
        },
        {
          id: 'store_home_display',
          label: t('main_frame::컬렉션 전시'),
          href: '/store_home/display',
          is_allowed:
            checkRes([], info.allowed_resource_list) &&
            isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]),
        },
        {
          id: 'store_home_style_content',
          label: t('main_frame::스타일 콘텐츠 관리'),
          href: '/store_home/style_content',
          is_allowed:
            checkRes([], info.allowed_resource_list) &&
            isAllowedMenuBySiteId(info, [SiteId.지그재그]) &&
            isAllowedMenuByCountry(info, [CountryCode.KR]),
          is_support_app: true,
        },
        {
          id: 'info_manage_model',
          label: t('main_frame::모델 정보 관리'),
          href: '/info/manage_model',
          is_allowed: checkRes([SellerResource.SHOP_SETTINGS], info.allowed_resource_list),
        },
        {
          id: 'video_list',
          label: t('main_frame::동영상 등록/관리'),
          href: VIDEO_LIST_URL,
          is_allowed:
            checkRes([SellerResource.VIDEO_MANAGEMENT], info.allowed_resource_list) &&
            isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]),
          is_support_app: true,
          is_beta: true,
        },
      ],
    },
  ];
}
