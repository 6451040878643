import { PageHeader, SectionTitle } from '@croquiscom/pds';
import React, { ReactNode } from 'react';
import useMediaQuery from '@/hooks/useMediaQuery';

interface Props {
  title: ReactNode;
  description?: ReactNode;
  text_button?: ReactNode;
}

const OrderItemListPageHeader = ({ title, description, text_button }: Props) => {
  const { is_desktop } = useMediaQuery();

  return (
    <PageHeader border={!is_desktop} dense={!is_desktop}>
      <SectionTitle
        title={title}
        direction='vertical'
        {...(is_desktop && {
          description,
          textButton: text_button,
        })}
      />
    </PageHeader>
  );
};

export default OrderItemListPageHeader;
