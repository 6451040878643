import { Tooltip, Button, text_styles, colors, semantic_colors, HStack } from '@croquiscom/pds';
import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InsightFeedbackPopover } from './InsightFeedbackPopover';
import { useGetPlan } from '@/components/insight/hooks/useGetPlan';
import { useLocation } from '@/components/route/LegacyRouterCompat';
import { useModalState } from 'rui/pastel/unstable/modal';

export const InsightFeedbackButton = ({ pageTitle }: { pageTitle: string }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [{ isOpen, onClose }, onToggle] = useModalState();
  const [has_been_shown, setHasBeenShown] = useState<boolean>(true);
  const { is_partner_plus } = useGetPlan();
  const handleClick = () => {
    setHasBeenShown(false);
    onToggle(!isOpen);
  };

  useLayoutEffect(() => {
    onClose();
  }, [location.pathname]);

  return (
    <HStack
      spacing={10}
      css={{
        position: 'relative',
      }}
    >
      {!is_partner_plus && (
        <a
          href='https://insight-kakaostyle.zendesk.com/hc/ko/requests/new?ticket_form_id=6850649083663'
          target='_blank'
          rel='noreferrer'
        >
          <Button css={[RoundedButton]}>{t('파트너플러스 구독 상담')}</Button>
        </a>
      )}
      <InsightFeedbackPopover isOpen={isOpen} onClose={onClose} pageTitle={pageTitle} />
      {isOpen ? (
        <Button css={[RoundedButton, OpenedButton]} onClick={handleClick}>
          {t('피드백')}
        </Button>
      ) : (
        <Tooltip
          content={t('이 페이지의 내 만족도를 알려주세요.')}
          openerTriggerEvent='none'
          zIndex={100}
          opened={has_been_shown}
        >
          <Button css={[RoundedButton]} onClick={handleClick}>
            {t('피드백')}
          </Button>
        </Tooltip>
      )}
    </HStack>
  );
};

const RoundedButton = `
  padding: 12px 16px;
  border: 1px solid ${colors.gray300};
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  background-color: ${colors.white};
  color: ${colors.gray900};
  cursor: pointer;
  ${text_styles.Body_14_SemiBold}

  &:hover {
    background-color: ${colors.gray300};
  }
  &:active {
    background-color: #E6E6E6;
  }
`;
const OpenedButton = `
  padding: 11px 15px;
  border: 2px solid ${colors.blue500};
  background-color: ${colors.white};
  color: ${semantic_colors.accent.primary};
`;
