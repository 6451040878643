import { HStack, TextButton, IconCircleInfo, Divider } from '@croquiscom/pds';
import React from 'react';
import { ExperienceGroupProductExposeExampleModal } from '@/components/promotion/experience_group/common/modal/ExperienceGroupProductExposeExampleModal';
import { ExperienceGroupScheduleExampleModal } from '@/components/promotion/experience_group/common/modal/ExperienceGroupScheduleExampleModal';
import { useModalState } from 'rui/pastel/unstable/modal';

export const ExperienceGroupExampleButtonGroup: React.FC = () => {
  const [schedule_example_modal] = useModalState();
  const [product_expose_example_modal] = useModalState();

  return (
    <>
      <HStack spacing={6} alignment='center'>
        <TextButton
          kind='link'
          startIcon={<IconCircleInfo width={16} height={16} />}
          onClick={() => window.open('https://partners.kakaostyle.com/help?id=809', '_blank', 'noopener')}
        >
          (구) 체험단 FAQ
        </TextButton>
        <Divider direction='vertical' style={{ height: 12 }} />
        <TextButton
          kind='link'
          startIcon={<IconCircleInfo width={16} height={16} />}
          onClick={schedule_example_modal.onOpen}
        >
          (구) 체험단 운영 일정 예시
        </TextButton>
        <Divider direction='vertical' style={{ height: 12 }} />
        <TextButton
          kind='link'
          startIcon={<IconCircleInfo width={16} height={16} />}
          onClick={product_expose_example_modal.onOpen}
        >
          (구) 상품 노출 예시
        </TextButton>
      </HStack>
      <ExperienceGroupScheduleExampleModal
        is_open={schedule_example_modal.isOpen}
        onClose={schedule_example_modal.onClose}
      />
      <ExperienceGroupProductExposeExampleModal
        is_open={product_expose_example_modal.isOpen}
        onClose={product_expose_example_modal.onClose}
      />
    </>
  );
};
