import { colors, IconCircleWarning, text_styles } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import TransPlainText from '@/components/common/TransPlainText';
import { Link } from '@/components/route/LegacyRouterCompat';

export interface ShopSyncErrorMessageProps {
  className?: string;
  title: React.ReactNode;
  children?: React.ReactNode;
  right?: React.ReactNode;
}

export const ShopSyncErrorMessage: React.FC<ShopSyncErrorMessageProps> = ({
  className,
  title,
  children = <TransPlainText ns='info' i18nKey='자세히 보기 >' />,
  right,
}) => {
  return (
    <StyledArticle className={className}>
      <IconCircleWarning size={50} color={colors.red500} />
      <StyledMessage>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>
          <Link to='/info/shop_sync'>{children}</Link>
        </StyledDescription>
      </StyledMessage>
      {right && <StyledRight>{right}</StyledRight>}
    </StyledArticle>
  );
};

const StyledArticle = styled.article`
  display: flex;
  align-items: center;
  margin: 40px;
  background-color: ${colors.gray200};
`;
const StyledMessage = styled.div`
  flex: 1 0 auto;
  margin-left: 20px;
`;
const StyledTitle = styled.h2`
  ${text_styles.Heading_20_SemiBold};
  color: ${colors.gray900};
`;
const StyledDescription = styled.p`
  margin: 10px 0 0;
  ${text_styles.Body_14_Regular};
  color: ${colors.gray900};
  a {
    color: ${colors.gray900};
  }
`;
const StyledRight = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;
