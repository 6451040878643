import { DataTableColumnsType } from '@croquiscom/pds';
import dayjs from 'dayjs';
import { t } from 'i18next';
import React, { ReactNode } from 'react';
import { CarryOutOrdererStateType } from './set_orderer/types';
import {
  WmsProductItemStockDetailInput,
  GetWarehousedInPageListQuery,
  WmsProductItemStockOrdererInput,
  WmsProductItemStockStatus,
  WmsProductItemStockTransportType,
  SearchWmsProductItemPageListQuery,
  SearchWmsProductItemStockPageListQuery,
  WmsProductItemStockInvoiceNumber,
} from '@/api';
import TransPlainText from '@/components/common/TransPlainText';

export type InputOption = 'all' | 'selected';
export const INPUT_OPTIONS: { value: InputOption; label: React.ReactNode }[] = [
  { value: 'all', label: <TransPlainText ns='zigzin' i18nKey='전체 반출' /> },
  { value: 'selected', label: <TransPlainText ns='zigzin' i18nKey='직접 입력' /> },
];

export type CarryOutStatus = WmsProductItemStockStatus | 'ALL';

export type CarryOutTransportType = WmsProductItemStockTransportType | 'ALL';

export const CARRY_OUT_STATUS: { label: React.ReactNode; value: CarryOutStatus }[] = [
  { label: <TransPlainText ns='zigzin' i18nKey='전체' />, value: 'ALL' },
  { label: <TransPlainText ns='zigzin' i18nKey='반출 요청 중' />, value: WmsProductItemStockStatus.REQUEST },
  { label: <TransPlainText ns='zigzin' i18nKey='반출 진행 중' />, value: WmsProductItemStockStatus.WAITING },
  { label: <TransPlainText ns='zigzin' i18nKey='반출 출고 완료' />, value: WmsProductItemStockStatus.COMPLETE },
  { label: <TransPlainText ns='zigzin' i18nKey='반출 취소 완료' />, value: WmsProductItemStockStatus.CANCEL },
];
export const DISABLED: CarryOutStatus[] = [WmsProductItemStockStatus.CANCEL];
export const ENABLED: CarryOutStatus[] = [
  WmsProductItemStockStatus.REQUEST,
  WmsProductItemStockStatus.WAITING,
  WmsProductItemStockStatus.COMPLETE,
];

export const TransportType = {
  COURIER: <TransPlainText ns='zigzin' i18nKey='택배반출' />,
  FREIGHT: <TransPlainText ns='zigzin' i18nKey='화물반출' />,
  SELF: <TransPlainText ns='zigzin' i18nKey='직접반출' />,
};
export const SET_ORDERER_TRANSPORT_TYPE: { value: WmsProductItemStockTransportType; label: React.ReactNode }[] = [
  { label: <TransPlainText ns='zigzin' i18nKey='택배반출' />, value: WmsProductItemStockTransportType.COURIER },
  // { label: <TransPlainText ns='zigzin' i18nKey='화물반출' />, value: WmsProductItemStockTransportType.FREIGHT },
  // { label: <TransPlainText ns='zigzin' i18nKey='직접반출' />, value: WmsProductItemStockTransportType.SELF },
];

export const TODAY = dayjs().toDate();

export const TRANSPORT_TYPE = {
  COURIER: <TransPlainText ns='zigzin' i18nKey='택배반출' />,
  FREIGHT: <TransPlainText ns='zigzin' i18nKey='화물반출' />,
  SELF: <TransPlainText ns='zigzin' i18nKey='직접반출' />,
};

export const MAX_EXCEL_ROWS = 20000;

export enum CarryOutListQueryOption {
  NONE = 'none',
  PRODUCT_TITLE = 'product_title',
  PRODUCT_ID = 'product_id',
  PRODUCT_ITEM_ID = 'product_item_id',
  OPTION_ID = 'option_id',
  SHOP_ID = 'shop_id',
  SHOP_NAME = 'shop_name',
  ID = 'id',
  INVOICE_NUMBER = 'invoice_number',
}

const PAGE_SIZE: number[] = [20, 50, 100, 300, 500];
export const PAGE_SIZE_OPTIONS: { label: React.ReactNode; value: number }[] = PAGE_SIZE.map((v) => ({
  label: <TransPlainText ns='zigzin' i18nKey='{{v}}개씩 보기' values={{ v }} />,
  value: v,
}));

export const QUERY_OPTION_VALUES = [
  { label: <TransPlainText ns='zigzin' i18nKey='상세조건 전체' />, value: CarryOutListQueryOption.NONE },
  { label: <TransPlainText ns='zigzin' i18nKey='상품명' />, value: CarryOutListQueryOption.PRODUCT_TITLE },
  { label: <TransPlainText ns='zigzin' i18nKey='SKU 번호' />, value: CarryOutListQueryOption.PRODUCT_ITEM_ID },
  { label: <TransPlainText ns='zigzin' i18nKey='상품번호' />, value: CarryOutListQueryOption.PRODUCT_ID },
  { label: <TransPlainText ns='zigzin' i18nKey='반출전표' />, value: CarryOutListQueryOption.ID },
  { label: <TransPlainText ns='zigzin' i18nKey='운송장조회' />, value: CarryOutListQueryOption.INVOICE_NUMBER },
  { label: <TransPlainText ns='zigzin' i18nKey='옵션번호' />, value: CarryOutListQueryOption.OPTION_ID },
];

export const ZONLY_QUERY_OPTION_VALUES = [
  ...QUERY_OPTION_VALUES,
  { label: <TransPlainText ns='zigzin' i18nKey='셀러샵 ID' />, value: CarryOutListQueryOption.SHOP_ID },
  { label: <TransPlainText ns='zigzin' i18nKey='셀러명' />, value: CarryOutListQueryOption.SHOP_NAME },
];

export const CARRY_OUT_STEPS = [
  <TransPlainText ns='zigzin' i18nKey='반출 상품 선택' />,
  <TransPlainText ns='zigzin' i18nKey='반출지 정보 입력' />,
  <TransPlainText ns='zigzin' i18nKey='최종확인' />,
];

export enum DateRangeOptions {
  RESERVED = 'reserved',
  COMPLETED = 'completed',
}

export const DATE_RANGE_OPTIONS = [
  { label: <TransPlainText ns='zigzin' i18nKey='반출희망일' />, value: DateRangeOptions.RESERVED },
  { label: <TransPlainText ns='zigzin' i18nKey='반출완료일' />, value: DateRangeOptions.COMPLETED },
];

export const CARRY_OUT_TRANSPORT_TYPE_OPTIONS = [
  { label: <TransPlainText ns='zigzin' i18nKey='전체' />, value: 'ALL' },
  { label: <TransPlainText ns='zigzin' i18nKey='택배' />, value: WmsProductItemStockTransportType.COURIER },
  { label: <TransPlainText ns='zigzin' i18nKey='화물' />, value: WmsProductItemStockTransportType.FREIGHT },
];

export const SIX_MONTH_LATER = dayjs().add(6, 'month').toDate();

export const RESERVED_DATE_PRESET: Array<{ id: number; label: () => string; range: [Date, Date] }> = [
  { id: 0, label: () => t('zigzin::오늘부터'), range: [TODAY, SIX_MONTH_LATER] },
  {
    id: 1,
    label: () => t('zigzin::1주일부터'),
    range: [dayjs().subtract(6, 'days').toDate(), SIX_MONTH_LATER],
  },
  {
    id: 2,
    label: () => t('zigzin::1개월부터'),
    range: [dayjs().subtract(1, 'months').subtract(1, 'days').toDate(), SIX_MONTH_LATER],
  },
  {
    id: 3,
    label: () => t('zigzin::3개월부터'),
    range: [dayjs().subtract(3, 'months').subtract(1, 'days').toDate(), SIX_MONTH_LATER],
  },
];

// 대표샵용 기간 조회 프리셋
export const ZONLY_DATE_RANGE = {
  from: new Date(),
  to: new Date(),
};

export const COMPLETED_DATE_PRESET: Array<{ id: number; label: () => string; range: [Date, Date] }> = [
  { id: 0, label: () => t('zigzin::오늘'), range: [TODAY, TODAY] },
  {
    id: 1,
    label: () => t('zigzin::1주일'),
    range: [dayjs().subtract(6, 'days').toDate(), TODAY],
  },
  {
    id: 2,
    label: () => t('zigzin::1개월'),
    range: [dayjs().subtract(1, 'months').subtract(1, 'days').toDate(), TODAY],
  },
  {
    id: 3,
    label: () => t('zigzin::3개월'),
    range: [dayjs().subtract(3, 'months').subtract(1, 'days').toDate(), TODAY],
  },
];

export type CarryOutInvoice = WmsProductItemStockDetailInput & {
  id: string;
  product_id: string;
  product_item_id: string;
  option_id: string;
  product_name?: string | null;
  wms_product_item_option_list: NonNullable<
    GetWarehousedInPageListQuery['wms_product_item_stock_page_list']['item_list'][number]['wms_product_item']
  >['wms_product_item_option_list'];
  total_available_quantity?: number | null;
  total_long_term_quantity?: number | null;
  date_recent_warehoused_in?: number | null;
  seller_request_quantity?: number | null;
  date_reserved?: number;
  orderer_list?: Array<WmsProductItemStockOrdererInput | null> | null;
  orderer_state?: CarryOutOrdererStateType;
  image_url?: string | null;
};

export interface CarryOutInvoiceDetail {
  dateReserved?: number | null;
  orderer_list?: Array<WmsProductItemStockOrdererInput | null> | null;
  totalProductItemId: number;
  totalSellerRequestQuantity: number;
}

export const DEFAULT_FILTER_OPTIONS: { sortBy: string; sortDirection: 'increment' | 'decrement' | '' } = {
  sortBy: 'PRODUCT_ITEM_ID',
  sortDirection: 'decrement',
};

export interface CarryOutDataType {
  id: ReactNode;
  product_item_id: ReactNode;
  option_id: ReactNode;
  status: ReactNode;
  wms_product_item_product_name: ReactNode;
  wms_product_item_wms_product_item_option: ReactNode;
  orderer_transport_type: ReactNode;
  package_info: ReactNode;
  invoice_number: ReactNode;
  date_reserved: ReactNode;
  date_completed: ReactNode;
  seller_request_quantity: ReactNode;
  wms_confirmed_quantity: ReactNode;
  doer: ReactNode;
  shop_name: ReactNode;
  orderer_name: string;
  orderer_mobile: string;
  orderer_address: string;
  memo: ReactNode;
}

export const DATA_TABLE_LABELS: DataTableColumnsType<CarryOutDataType> = [
  { id: 'id', text: <TransPlainText ns='zigzin' i18nKey='반출전표' />, width: 150 },
  { id: 'product_item_id', text: <TransPlainText ns='zigzin' i18nKey='SKU 번호' />, width: 150 },
  { id: 'option_id', text: <TransPlainText ns='zigzin' i18nKey='옵션번호' />, width: 150 },
  { id: 'status', text: <TransPlainText ns='zigzin' i18nKey='반출 처리 상태' />, width: 150 },
  { id: 'wms_product_item_product_name', text: <TransPlainText ns='zigzin' i18nKey='상품명' />, width: 300 },
  {
    id: 'wms_product_item_wms_product_item_option',
    text: <TransPlainText ns='zigzin' i18nKey='옵션 정보' />,
    width: 200,
  },
  { id: 'orderer_transport_type', text: <TransPlainText ns='zigzin' i18nKey='반출 방식' />, width: 150 },
  { id: 'package_info', text: <TransPlainText ns='zigzin' i18nKey='반출 방식 상세' />, width: 220 },
  { id: 'invoice_number', text: <TransPlainText ns='zigzin' i18nKey='송장번호' />, width: 150 },
  { id: 'date_reserved', text: <TransPlainText ns='zigzin' i18nKey='반출예정일' />, width: 100 },
  { id: 'date_completed', text: <TransPlainText ns='zigzin' i18nKey='반출완료일' />, width: 100 },
  { id: 'seller_request_quantity', text: <TransPlainText ns='zigzin' i18nKey='반출요청수량' />, width: 100 },
  { id: 'wms_confirmed_quantity', text: <TransPlainText ns='zigzin' i18nKey='실반출수량' />, width: 100 },
  { id: 'orderer_name', text: <TransPlainText ns='zigzin' i18nKey='담당자' />, width: 100 },
  { id: 'orderer_mobile', text: <TransPlainText ns='zigzin' i18nKey='담당자 연락처' />, width: 150 },
  { id: 'orderer_address', text: <TransPlainText ns='zigzin' i18nKey='반출지 주소' />, width: 250 },
  { id: 'doer', text: <TransPlainText ns='zigzin' i18nKey='작성자' />, width: 200 },
  { id: 'memo', text: <TransPlainText ns='zigzin' i18nKey='메모' />, width: 180 },
];

export const ZONLY_DATA_TABLE_LABELS: DataTableColumnsType<CarryOutDataType> = [...DATA_TABLE_LABELS];
ZONLY_DATA_TABLE_LABELS.splice(1, 0, {
  id: 'shop_name',
  text: <TransPlainText ns='zigzin' i18nKey='셀러명' />,
  width: 150,
});

export type SearchWmsProductItemPageListData =
  SearchWmsProductItemPageListQuery['wms_product_item_page_list']['item_list'][number];

export type SearchWmsProductItemStockPageListData =
  SearchWmsProductItemStockPageListQuery['wms_product_item_stock_page_list']['item_list'][number];

export interface CarryOutUpdatedSellerRequestQuantityDataType {
  product_item_id: string;
  product_name: ReactNode;
  wms_product_item_option: string;
  date_reserved: string;
  seller_request_quantity: ReactNode;
}

export const CARRY_OUT_UPDATED_SELLER_REQUEST_QUANTITY_DATA_TABLE_LABELS: DataTableColumnsType<CarryOutUpdatedSellerRequestQuantityDataType> =
  [
    { id: 'product_item_id', text: <TransPlainText ns='zigzin' i18nKey='SKU 번호' />, width: 160 },
    { id: 'product_name', text: <TransPlainText ns='zigzin' i18nKey='상품명' /> },
    { id: 'wms_product_item_option', text: <TransPlainText ns='zigzin' i18nKey='옵션정보' />, width: 200 },
    { id: 'date_reserved', text: <TransPlainText ns='zigzin' i18nKey='반출 희망일' />, width: 100 },
    { id: 'seller_request_quantity', text: <TransPlainText ns='zigzin' i18nKey='반출 요청 수량' />, width: 100 },
  ];

export type CarryOutInvoiceNumberDataType = Pick<
  WmsProductItemStockInvoiceNumber,
  'transport_company' | 'invoice_number' | 'quantity'
>;

export const CARRY_OUT_INVOICE_NUMBER_TABLE_LABELS: DataTableColumnsType<CarryOutInvoiceNumberDataType> = [
  { id: 'transport_company', text: <TransPlainText ns='zigzin' i18nKey='운송사' /> },
  { id: 'invoice_number', text: <TransPlainText ns='zigzin' i18nKey='운송장번호' /> },
  { id: 'quantity', text: <TransPlainText ns='zigzin' i18nKey='수량' /> },
];
