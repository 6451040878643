import { PageHeader, PageHeaderProps, SectionTitle } from '@croquiscom/pds';
import React from 'react';
import useMediaQuery from '@/hooks/useMediaQuery';

interface ResponsivePageHeaderProps extends PageHeaderProps {
  title: string;
  description?: React.ReactNode;
  actions?: React.ReactNode;
}

export default function ResponsivePageHeader({ title, description, actions, ...props }: ResponsivePageHeaderProps) {
  const { is_desktop } = useMediaQuery();

  return (
    <PageHeader dense={!is_desktop} {...props}>
      <SectionTitle title={title} description={description} actions={actions} />
    </PageHeader>
  );
}
