import { VStack, BaseText, semantic_colors, text_styles, shapes, IconColoredWarningRed, IconX } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { useEffect, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { usePbl } from '@/hooks/usePbl';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { PblParams } from '@/utils/pbl';
import { useOnceReveal } from 'rui/hooks/useOnceReveal';

export interface ImportantCardProps {
  title: string;
  description: string;
  extra_text?: string;
  link: string;
  linkText?: string;
  important?: boolean;
  closeButton?: boolean;
  onClose?: () => void;
  onClickLink?: () => void;
}

export const ImportantCard: React.FC<ImportantCardProps> = ({
  title,
  description,
  extra_text,
  link,
  important,
  closeButton = false,
  onClose,
  onClickLink,
}) => {
  const { isRevealed, targetRef } = useOnceReveal({ threshold: 0.8 });
  const { pbl } = usePbl();
  const prefix = useShopPrefix();
  const history = useHistory();

  const handleClose = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClose?.();
  };

  const shared_pbl_params: Pick<PblParams, 'navigation' | 'object_id' | 'object_section' | 'extra'> = {
    navigation: 'partnercenter_notification_inbox',
    object_id: 'message',
    object_section: '중요',
    extra: JSON.stringify({ message_title: title }),
  };

  const handleClickLink = () => {
    if (!link) return;
    onClickLink?.();
    pbl({
      ...shared_pbl_params,
      category: 'click',
    });
    history.push(`${prefix}${link}`);
  };

  useEffect(() => {
    if (isRevealed) {
      pbl({
        ...shared_pbl_params,
        category: 'impression',
      });
    }
  }, [isRevealed]);

  return (
    <Container ref={targetRef} onClick={handleClickLink} hasLink={!!link}>
      {closeButton && (
        <CloseButton onClick={handleClose}>
          <IconX size={18} color='currentColor' />
        </CloseButton>
      )}
      <VStack spacing={6}>
        {important ? (
          <BaseText kind='Heading_15_Bold' color={semantic_colors.state.negative} as='h1'>
            <WarningIcon size={16} />
            {title}
          </BaseText>
        ) : (
          <BaseText kind='Heading_15_Bold' as='h1'>
            {title}
          </BaseText>
        )}
        <Content>
          <BaseText as='p'>{description}</BaseText>
          {extra_text && (
            <BaseText as='p' color={semantic_colors.accent.primary}>
              {extra_text}
            </BaseText>
          )}
        </Content>
      </VStack>
    </Container>
  );
};

const WarningIcon = styled(IconColoredWarningRed)`
  margin: 5px 8px 0 0;
  vertical-align: top;
`;

const Container = styled.button<{ hasLink: boolean }>`
  position: relative;
  background-color: ${semantic_colors.background.surface};
  color: ${semantic_colors.content.primary};
  ${text_styles.Body_13_Regular};
  ${shapes.border_radius.small};
  word-break: keep-all;
  padding: 18px 20px;
  row-gap: 6px;
  border: 0;
  text-align: left;
  ${({ hasLink }) =>
    hasLink &&
    `
    transition: box-shadow 0.2s;
    &:hover {
      cursor: pointer;
      box-shadow: 0px 4px 16px 0px #0000001a;
    }`}
`;
const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  background-color: transparent;
  border: none;
  color: ${semantic_colors.content.tertiary};
  cursor: pointer;
  ${shapes.border_radius.small};
  text-decoration: none;

  &:hover,
  &:focus-visible,
  &:active {
    color: ${semantic_colors.content.tertiary};
  }
`;
const Content = styled.div`
  white-space: pre-line;
`;
