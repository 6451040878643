import { BaseText, HStack, Modal, VStack, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import Example_1 from '@/assets/images/promotion/price_optimization/example_1.png';
import Example_2 from '@/assets/images/promotion/price_optimization/example_2.png';
import Example_3 from '@/assets/images/promotion/price_optimization/example_3.png';
import Example_4 from '@/assets/images/promotion/price_optimization/example_4.png';

interface ExampleModalProps {
  opened: boolean;
  onClose: () => void;
}

const ExampleModal: React.FC<ExampleModalProps> = ({ opened, onClose }) => {
  return (
    <Modal title='노출 확대 안내' width={900} contentProps={{ scrollable: true }} opened={opened} onCancel={onClose}>
      <VStack spacing={24}>
        <VStack spacing={8}>
          <BaseText kind='Heading_15_Bold'>노출 확대는 이렇게 제공됩니다.</BaseText>
          <BaseText color={semantic_colors.content.secondary}>
            경쟁이 치열한 여러 핵심 지면에서 상품 경쟁력을 한층 강화할 수 있습니다.
            <br />
            노출 확대 기회가 있는 상품에 추천가를 적용하고, 노출을 확대하거나 보호하세요.
          </BaseText>
        </VStack>
        <VStack spacing={8}>
          <BaseText kind='Heading_15_Bold'>예시 화면</BaseText>
          <BaseText color={semantic_colors.content.secondary}>
            최저가 도전 뱃지는 가격 최적화된 상품 중 일부에게 적용됩니다.
          </BaseText>
          <HStack spacing={10}>
            <VStack width={204} spacing={10} alignment='center'>
              <SC.ImageContainer>
                <SC.Image width='100%' src={Example_1} alt='' />
              </SC.ImageContainer>
              <BaseText kind='Body_14_SemiBold'>검색 결과</BaseText>
            </VStack>
            <VStack width={204} spacing={10} alignment='center'>
              <SC.ImageContainer>
                <SC.Image width='100%' src={Example_2} alt='' />
              </SC.ImageContainer>
              <BaseText kind='Body_14_SemiBold'>카테고리 별 상품 목록</BaseText>
            </VStack>
            <VStack width={204} spacing={10} alignment='center'>
              <SC.ImageContainer>
                <SC.Image width='100%' src={Example_3} alt='' />
              </SC.ImageContainer>
              <BaseText kind='Body_14_SemiBold'>상품 상세 페이지 추천 영역</BaseText>
            </VStack>
            <VStack width={204} spacing={10} alignment='center'>
              <SC.ImageContainer>
                <SC.Image width='100%' src={Example_4} alt='' />
              </SC.ImageContainer>
              <BaseText kind='Body_14_SemiBold'>최저가 도전 뱃지</BaseText>
            </VStack>
          </HStack>
        </VStack>
      </VStack>
    </Modal>
  );
};

export default ExampleModal;

const SC = {
  ImageContainer: styled(VStack)`
    border-radius: 10px;
    border: 2px solid ${semantic_colors.border.default};
    overflow: hidden;
  `,
  Image: styled.img`
    width: 100%;
    object-fit: cover;
  `,
};
