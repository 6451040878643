import { Divider, HStack, PageHeader, SectionTitle } from '@croquiscom/pds';
import React, { lazy } from 'react';
import { RoutesType } from '../types';
import { CatalogExcelImportType, SellerResource } from '@/api';
import CatalogDetailSectionTitle from '@/components/catalog/detail/CatalogDetailSectionTitle';
import CatalogDetailCrawlingDescription from '@/components/catalog/detail/crawling/CatalogDetailCrawlingDescription';
import CatalogDetailCrawlingTitle from '@/components/catalog/detail/crawling/CatalogDetailCrawlingTitle';
import CatalogNoticeMainSectionTitle from '@/components/catalog/notice/main/CatalogNoticeMainSectionTitle';
import { GUIDE_CATALOG_ITEM_DETAIL } from '@/components/common/guide/constants';
import TransPlainText from '@/components/common/TransPlainText';
import {
  CATALOG_ITEM_DETAIL_CRAWLING_URL,
  CATALOG_ITEM_DETAIL_NEW_URL,
  CATALOG_ITEM_DETAIL_URL,
  CATALOG_ITEM_LIST_URL,
  CATALOG_NOTICE_URL,
  EXCEL_UPLOAD_URL,
  EXCLUSIVE_CATALOG_APPLY,
  getCatalogExcelUploadFailureByImportTypeURL,
  getCatalogExcelUploadFailURL,
  IMPORT_EXTERNAL_CATALOG_URL,
} from 'rui/components/URL';

const ItemPriceExcelUploadFailDetailPage = lazy(() => import('@/pages/catalog/ItemPriceExcelUploadFailDetailPage'));
const CreateProductPricePolicyFailDetailPage = lazy(
  () => import('@/pages/catalog/CreateProductPricePolicyFailDetailPage'),
);
const UpdateProductPricePolicyFailDetailPage = lazy(
  () => import('@/pages/catalog/UpdateProductPricePolicyFailDetailPage'),
);
const ItemInventoryExcelUploadFailDetailPage = lazy(
  () => import('@/pages/catalog/ItemInventoryExcelUploadFailDetailPage'),
);
const ItemShippingExcelUploadFailDetailPage = lazy(
  () => import('@/pages/catalog/ItemShippingExcelUploadFailDetailPage'),
);
const ProductShippingExcelUploadFailDetailPage = lazy(
  () => import('@/pages/catalog/ProductShippingExcelUploadFailDetailPage'),
);
const CatalogExcelUploadFailDetailPage = lazy(() => import('@/pages/catalog/CatalogExcelUploadFailDetailPage'));
const CatalogExcelUploadPage = lazy(() => import('@/pages/catalog/CatalogExcelUploadPage'));
const CatalogItemDetailCrawlingPage = lazy(() => import('@/pages/catalog/CatalogItemDetailCrawlingPage'));
const CatalogItemDetailPage = lazy(() => import('@/pages/catalog/CatalogItemDetailPage'));
const CatalogListPage = lazy(() => import('@/pages/catalog/CatalogListPage'));
const CatalogNoticeDetailPage = lazy(() => import('@/pages/catalog/CatalogNoticeDetailPage'));
const CatalogNoticeListPage = lazy(() => import('@/pages/catalog/CatalogNoticeListPage'));

const ImportExternalCatalogPage = lazy(() => import('@/pages/catalog/ImportExternalCatalogPage'));
const ExclusiveCatalogApplyPage = lazy(() => import('@/pages/catalog/ExclusiveCatalogApplyPage'));

const PromotionCreatePage = lazy(() => import('@/pages/promotion/bogo/create/PromotionCreatePage'));
const PromotionHistoryPage = lazy(() => import('@/pages/promotion/bogo/history/PromotionHistoryPage'));
const PromotionEditPage = lazy(() => import('@/pages/promotion/bogo/edit/PromotionEditPage'));

export const CATALOG_ROUTES: RoutesType[] = [
  {
    current_menu: 'catalog_item_list',
    path: CATALOG_ITEM_LIST_URL,
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title={<TransPlainText i18nKey='상품 조회/수정' ns='catalog' />}
            description={<TransPlainText i18nKey='상품을 조회하고 수정하는 화면입니다.' ns='catalog' />}
            textButton={
              <HStack spacing={6} alignment='center'>
                {GUIDE_CATALOG_ITEM_DETAIL.LIST_1}
                <Divider direction='vertical' style={{ height: 12 }} />
                {GUIDE_CATALOG_ITEM_DETAIL.LIST_2}
              </HStack>
            }
          />
        </PageHeader>
        <CatalogListPage />
      </>
    ),
    res: SellerResource.GOODS_SEARCH,
  },
  {
    current_menu: 'catalog_item_list',
    path: CATALOG_ITEM_DETAIL_CRAWLING_URL,
    params: '/:id',
    component: (
      <>
        <PageHeader>
          <SectionTitle title={<CatalogDetailCrawlingTitle />} />
          <CatalogDetailCrawlingDescription />
        </PageHeader>
        <CatalogItemDetailCrawlingPage />
      </>
    ),
    res: SellerResource.GOODS_MANAGEMENT,
  },
  {
    current_menu: 'catalog_item_detail_new',
    path: CATALOG_ITEM_DETAIL_NEW_URL,
    params: '',
    component: (
      <>
        <PageHeader>
          <CatalogDetailSectionTitle is_new />
        </PageHeader>
        <CatalogItemDetailPage />
      </>
    ),
    res: SellerResource.GOODS_MANAGEMENT,
  },
  {
    current_menu: 'catalog_item_list',
    path: CATALOG_ITEM_DETAIL_URL,
    params: '/:id',
    component: (
      <>
        <PageHeader>
          <CatalogDetailSectionTitle />
        </PageHeader>
        <CatalogItemDetailPage />
      </>
    ),
    res: SellerResource.GOODS_MANAGEMENT,
  },
  {
    current_menu: 'excel_upload_catalog_fail',
    path: getCatalogExcelUploadFailURL('catalog'),
    params: '/:id',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title={<TransPlainText i18nKey='상품 등록 실패 상세' ns='catalog' />}
            description={
              <TransPlainText
                i18nKey='엑셀 파일 업로드 시도 후 등록 실패한 상품을 확인할 수 있습니다. 등록 실패한 상품만 정보를 수정한 후 엑셀 파일을 다시 업로드해 주세요.'
                ns='catalog'
              />
            }
          />
        </PageHeader>
        <CatalogExcelUploadFailDetailPage />
      </>
    ),
    res: SellerResource.GOODS_MANAGEMENT,
  },
  {
    current_menu: 'excel_upload_item_price_fail',
    path: getCatalogExcelUploadFailureByImportTypeURL('edit_catalog', CatalogExcelImportType.ITEM_PRICE),
    params: '/:id',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title={<TransPlainText i18nKey='판매가 수정 실패 상세' ns='catalog' />}
            description={
              <TransPlainText
                i18nKey='판매가 수정에 실패한 업로드 내역을 확인할 수 있습니다. 등록 실패한 상품의 정보를 수정한 후 엑셀 파일을 다시 업로드해 주세요.'
                ns='catalog'
              />
            }
          />
        </PageHeader>
        <ItemPriceExcelUploadFailDetailPage />
      </>
    ),
    res: SellerResource.GOODS_MANAGEMENT,
  },
  {
    current_menu: 'excel_upload_create_product_price_policy_fail',
    path: getCatalogExcelUploadFailureByImportTypeURL(
      'edit_catalog',
      CatalogExcelImportType.CREATE_PRODUCT_PRICE_POLICY,
    ),
    params: '/:id',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title={<TransPlainText i18nKey='판매가(자사몰 할인 적용) 등록 실패 상세' ns='catalog' />}
            description={
              <TransPlainText
                i18nKey='판매가(자사몰 할인 적용) 등록에 실패한 업로드 내역을 확인할 수 있습니다. 판매가(자사몰 할인 적용) 정보를 수정한 후 엑셀 파일을 다시 업로드해 주세요.'
                ns='catalog'
              />
            }
          />
        </PageHeader>
        <CreateProductPricePolicyFailDetailPage />
      </>
    ),
    res: SellerResource.GOODS_MANAGEMENT,
  },
  {
    current_menu: 'excel_upload_update__product_price_policy_fail',
    path: getCatalogExcelUploadFailureByImportTypeURL(
      'edit_catalog',
      CatalogExcelImportType.UPDATE_PRODUCT_PRICE_POLICY,
    ),
    params: '/:id',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title={<TransPlainText i18nKey='판매가(자사몰 할인 적용) 수정 실패 상세' ns='catalog' />}
            description={
              <TransPlainText
                i18nKey='판매가(자사몰 할인 적용) 수정에 실패한 업로드 내역을 확인할 수 있습니다. 판매가(자사몰 할인 적용) 정보를 수정한 후 엑셀 파일을 다시 업로드해 주세요.'
                ns='catalog'
              />
            }
          />
        </PageHeader>
        <UpdateProductPricePolicyFailDetailPage />
      </>
    ),
    res: SellerResource.GOODS_MANAGEMENT,
  },
  {
    current_menu: 'excel_upload_item_inventory_fail',
    path: getCatalogExcelUploadFailureByImportTypeURL('edit_catalog', CatalogExcelImportType.ITEM_INVENTORY),
    params: '/:id',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title={<TransPlainText i18nKey='재고 수정 실패 상세' ns='catalog' />}
            description={
              <TransPlainText
                i18nKey='재고 수정에 실패한 업로드 내역을 확인할 수 있습니다. 등록 실패한 상품의 정보를 수정한 후 엑셀 파일을 다시 업로드해 주세요.'
                ns='catalog'
              />
            }
          />
        </PageHeader>
        <ItemInventoryExcelUploadFailDetailPage />
      </>
    ),
    res: SellerResource.GOODS_MANAGEMENT,
  },
  {
    current_menu: 'excel_upload_item_shipping_fail',
    path: getCatalogExcelUploadFailureByImportTypeURL('shipping_days', CatalogExcelImportType.ITEM_SHIPPING),
    params: '/:id',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title={<TransPlainText i18nKey='옵션별 발송소요일 수정 실패 상세' ns='catalog' />}
            description={
              <TransPlainText
                i18nKey='옵션별 발송소요일 수정에 실패한 업로드 내역을 확인할 수 있습니다. 등록 실패한 상품의 정보를 수정한 후 엑셀 파일을 다시 업로드해 주세요. '
                ns='catalog'
              />
            }
          />
        </PageHeader>
        <ItemShippingExcelUploadFailDetailPage />
      </>
    ),
    res: SellerResource.GOODS_MANAGEMENT,
  },
  {
    current_menu: 'excel_upload_product_shipping_fail',
    path: getCatalogExcelUploadFailureByImportTypeURL('shipping_days', CatalogExcelImportType.PRODUCT_SHIPPING),
    params: '/:id',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title={<TransPlainText i18nKey='발송소요일 기본값 수정 실패 상세' ns='catalog' />}
            description={
              <TransPlainText
                i18nKey='발송소요일 기본값 수정에 실패한 업로드 내역을 확인할 수 있습니다. 등록 실패한 상품의 정보를 수정한 후 엑셀 파일을 다시 업로드해 주세요.'
                ns='catalog'
              />
            }
          />
        </PageHeader>
        <ProductShippingExcelUploadFailDetailPage />
      </>
    ),
    res: SellerResource.GOODS_MANAGEMENT,
  },
  {
    current_menu: 'excel_upload',
    path: EXCEL_UPLOAD_URL,
    params: '',
    component: <CatalogExcelUploadPage />,
    res: SellerResource.GOODS_MANAGEMENT,
  },
  {
    current_menu: 'catalog_notice_detail',
    path: CATALOG_NOTICE_URL,
    params: '/:id',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='상품 공지사항 등록' />
        </PageHeader>
        <CatalogNoticeDetailPage />
      </>
    ),
  },
  {
    current_menu: 'catalog_notice_list',
    path: CATALOG_NOTICE_URL,
    params: '',
    component: (
      <>
        <PageHeader>
          <CatalogNoticeMainSectionTitle />
        </PageHeader>
        <CatalogNoticeListPage />
      </>
    ),
  },
  {
    current_menu: 'import_external_catalog',
    path: IMPORT_EXTERNAL_CATALOG_URL,
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title={<TransPlainText ns='catalog' i18nKey='외부 상품 가져오기' />}
            description={
              <TransPlainText
                ns='catalog'
                i18nKey='외부에서 판매하는 상품을 원셀 솔루션을 통해 카카오스타일에 등록형 상품으로 등록할 수 있습니다.'
              />
            }
          />
        </PageHeader>
        <ImportExternalCatalogPage />
      </>
    ),
  },
  {
    current_menu: 'exclusive_catalog_apply',
    path: EXCLUSIVE_CATALOG_APPLY,
    params: '',
    component: (
      <>
        <PageHeader border={false}>
          <SectionTitle
            title='단독 상품 신청'
            description='자사몰과 지그재그에서만 판매되는 상품은 ‘단독 상품’ 으로 신청하실 수 있습니다.'
          />
        </PageHeader>
        <ExclusiveCatalogApplyPage />
      </>
    ),
  },
  {
    current_menu: 'catalog_promotion_history',
    path: '/promotion/bogo/history',
    params: '',
    component: <PromotionHistoryPage />,
  },
  {
    current_menu: 'catalog_promotion_create',
    path: '/promotion/bogo/create',
    params: '',
    component: <PromotionCreatePage />,
  },
  {
    current_menu: 'catalog_promotion_edit',
    path: '/promotion/bogo/edit',
    params: '/:promotion_id',
    component: <PromotionEditPage />,
  },
];
