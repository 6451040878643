import { PageHeader, SectionTitle } from '@croquiscom/pds';
import React, { lazy } from 'react';
import { SellerResource } from '@/api';
import { RoutesType } from '@/routes/types';

const MessageListPage = lazy(() => import('@/pages/message/MessageListPage'));

export const MESSAGE_ROUTES: RoutesType[] = [
  {
    current_menu: 'message_list',
    path: '/message/list',
    params: '',
    component: (
      <>
        <PageHeader border={false}>
          <SectionTitle title='쿠폰 타게팅 메시지 성과' description='발송한 메시지 내역을 확인할 수 있습니다' />
        </PageHeader>
        <MessageListPage />
      </>
    ),
    res: SellerResource.MESSAGE_MANAGEMENT_READ,
  },
];
