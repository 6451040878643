import { PageHeader, SectionTitle } from '@croquiscom/pds';
import React, { lazy } from 'react';
import { RoutesType } from '../types';
import { SellerResource } from '@/api';
import { INFO_SHIPPING_ADDRESS_URL, INFO_SHIPPING_TEMPLATE_LIST_URL } from 'rui/components/URL';

const InfoAgencyPage = lazy(() => import('@/pages/info/agency/InfoAgencyPage'));
const InfoBrandLicensePage = lazy(() => import('@/pages/info/brand_license/InfoBrandLicensePage'));
const InfoContactPage = lazy(() => import('@/pages/info/contact/InfoContactPage'));
const InfoFileRegisterPage = lazy(() => import('@/pages/info/file_register/InfoFileRegisterPage'));
const InfoIndexPage = lazy(() => import('@/pages/info/InfoIndexPage'));
const InfoManageManagersPage = lazy(() => import('@/pages/info/manage_managers/InfoManageManagersPage'));
const InfoManageModelPage = lazy(() => import('@/pages/info/manage_model/InfoManageModelPage'));
const InfoSellerPaymentPage = lazy(() => import('@/pages/info/seller_payment/InfoSellerPaymentPage'));
const InfoShopAuthKeyPage = lazy(() => import('@/pages/info/shop_auth_key/InfoShopAuthKeyPage'));
const InfoShippingAddressPage = lazy(() => import('@/pages/info/shipping_address/InfoShippingAddressPage'));
const InfoShippingTemplateListPage = lazy(() => import('@/pages/info/shipping_template/InfoShippingTemplateListPage'));
const InfoShopSyncPage = lazy(() => import('@/pages/info/shop_sync/InfoShopSyncPage'));
const InfoTraitPage = lazy(() => import('@/pages/info/trait/InfoTraitPage'));
const InfoTraitSimplePage = lazy(() => import('@/pages/info/trait/InfoTraitSimplePage'));
const InfoStoryPage = lazy(() => import('@/pages/info/story/InfoStoryPage'));
const InfoAccessHistoryPage = lazy(() => import('@/pages/info/access_history'));

export const INFO_ROUTES: RoutesType[] = [
  {
    is_support_app: true,
    current_menu: 'info',
    path: '/info',
    params: '',
    component: <InfoIndexPage />,
    res: [
      SellerResource.SHOP_SETTINGS,
      SellerResource.SHOP_SETTINGS_TRAIT,
      SellerResource.SHOP_SETTINGS_SHOP_SYNC_READ,
      SellerResource.SHOP_SETTINGS_BASIC,
      SellerResource.SHOP_SETTINGS_EXPANSION,
    ],
  },
  {
    is_support_app: true,
    is_support_mobile: true,
    current_menu: 'info_trait',
    path: '/info/trait',
    params: '',
    component: <InfoTraitPage />,
    res: [
      SellerResource.SHOP_SETTINGS,
      SellerResource.SHOP_SETTINGS_TRAIT,
      SellerResource.SHOP_SETTINGS_SHOP_SYNC_READ,
      SellerResource.SHOP_SETTINGS_BASIC,
      SellerResource.SHOP_SETTINGS_EXPANSION,
    ],
  },
  {
    is_support_app: true,
    current_menu: 'info_trait',
    path: '/info/trait_simple',
    params: '',
    component: <InfoTraitSimplePage />,
    res: [
      SellerResource.SHOP_SETTINGS,
      SellerResource.SHOP_SETTINGS_TRAIT,
      SellerResource.SHOP_SETTINGS_SHOP_SYNC_READ,
      SellerResource.SHOP_SETTINGS_BASIC,
      SellerResource.SHOP_SETTINGS_EXPANSION,
    ],
  },
  {
    current_menu: 'info_story',
    path: '/info/story',
    params: '',
    component: <InfoStoryPage />,
    res: [
      SellerResource.SHOP_SETTINGS,
      SellerResource.SHOP_SETTINGS_TRAIT,
      SellerResource.SHOP_SETTINGS_SHOP_SYNC_READ,
      SellerResource.SHOP_SETTINGS_BASIC,
      SellerResource.SHOP_SETTINGS_EXPANSION,
    ],
  },
  {
    current_menu: 'info_manage_managers',
    path: '/info/manage_managers',
    params: '',
    component: <InfoManageManagersPage />,
    res: [
      SellerResource.SHOP_SETTINGS,
      SellerResource.SHOP_SETTINGS_TRAIT,
      SellerResource.SHOP_SETTINGS_SHOP_SYNC_READ,
      SellerResource.SHOP_SETTINGS_BASIC,
      SellerResource.SHOP_SETTINGS_EXPANSION,
    ],
  },
  {
    current_menu: 'info_manage_model',
    path: '/info/manage_model',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='모델 정보 관리' />
        </PageHeader>
        <InfoManageModelPage />
      </>
    ),
    res: [
      SellerResource.SHOP_SETTINGS,
      SellerResource.SHOP_SETTINGS_TRAIT,
      SellerResource.SHOP_SETTINGS_SHOP_SYNC_READ,
      SellerResource.SHOP_SETTINGS_BASIC,
      SellerResource.SHOP_SETTINGS_EXPANSION,
    ],
  },
  {
    current_menu: 'info_shop_auth_key',
    path: '/info/shop_auth_key',
    params: '',
    component: (
      <>
        <InfoShopAuthKeyPage />
      </>
    ),
    res: [
      SellerResource.SHOP_SETTINGS,
      SellerResource.SHOP_SETTINGS_TRAIT,
      SellerResource.SHOP_SETTINGS_SHOP_SYNC_READ,
      SellerResource.SHOP_SETTINGS_BASIC,
      SellerResource.SHOP_SETTINGS_EXPANSION,
    ],
  },
  {
    current_menu: 'info_shop_sync',
    path: '/info/shop_sync',
    params: '',
    component: <InfoShopSyncPage />,
    res: [
      SellerResource.SHOP_SETTINGS,
      SellerResource.SHOP_SETTINGS_TRAIT,
      SellerResource.SHOP_SETTINGS_SHOP_SYNC_READ,
      SellerResource.SHOP_SETTINGS_BASIC,
      SellerResource.SHOP_SETTINGS_EXPANSION,
    ],
  },
  {
    current_menu: 'info_contact',
    path: '/info/contact',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='담당자 정보' />
        </PageHeader>
        <InfoContactPage />
      </>
    ),
    res: [
      SellerResource.SHOP_SETTINGS,
      SellerResource.SHOP_SETTINGS_TRAIT,
      SellerResource.SHOP_SETTINGS_SHOP_SYNC_READ,
      SellerResource.SHOP_SETTINGS_BASIC,
      SellerResource.SHOP_SETTINGS_EXPANSION,
    ],
  },
  {
    current_menu: 'info_agency',
    path: '/info/agency',
    params: '',
    component: <InfoAgencyPage />,
    res: [
      SellerResource.SHOP_SETTINGS,
      SellerResource.SHOP_SETTINGS_TRAIT,
      SellerResource.SHOP_SETTINGS_SHOP_SYNC_READ,
      SellerResource.SHOP_SETTINGS_BASIC,
      SellerResource.SHOP_SETTINGS_EXPANSION,
    ],
  },
  {
    current_menu: 'info_brand_license',
    path: '/info/brand_license',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='라이센스' />
        </PageHeader>
        <InfoBrandLicensePage />
      </>
    ),
    res: [
      SellerResource.SHOP_SETTINGS,
      SellerResource.SHOP_SETTINGS_TRAIT,
      SellerResource.SHOP_SETTINGS_SHOP_SYNC_READ,
      SellerResource.SHOP_SETTINGS_BASIC,
      SellerResource.SHOP_SETTINGS_EXPANSION,
    ],
  },
  {
    is_support_app: true,
    is_support_mobile: true,
    current_menu: 'info_file_register',
    path: '/info/file_register',
    params: '',
    component: <InfoFileRegisterPage />,
    res: [
      SellerResource.SHOP_SETTINGS,
      SellerResource.SHOP_SETTINGS_TRAIT,
      SellerResource.SHOP_SETTINGS_SHOP_SYNC_READ,
      SellerResource.SHOP_SETTINGS_BASIC,
      SellerResource.SHOP_SETTINGS_EXPANSION,
    ],
  },
  {
    current_menu: 'info_seller_payment',
    path: '/info/seller_payment',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='정산 정보' />
        </PageHeader>
        <InfoSellerPaymentPage />
      </>
    ),
    res: [
      SellerResource.SHOP_SETTINGS,
      SellerResource.SHOP_SETTINGS_TRAIT,
      SellerResource.SHOP_SETTINGS_SHOP_SYNC_READ,
      SellerResource.SHOP_SETTINGS_BASIC,
      SellerResource.SHOP_SETTINGS_EXPANSION,
    ],
  },
  {
    current_menu: 'info_shipping_address',
    path: INFO_SHIPPING_ADDRESS_URL,
    params: '',
    component: <InfoShippingAddressPage />,
    res: [
      SellerResource.SHOP_SETTINGS,
      SellerResource.SHOP_SETTINGS_TRAIT,
      SellerResource.SHOP_SETTINGS_SHOP_SYNC_READ,
      SellerResource.SHOP_SETTINGS_BASIC,
      SellerResource.SHOP_SETTINGS_EXPANSION,
    ],
  },
  {
    current_menu: 'info_shipping_address',
    path: INFO_SHIPPING_TEMPLATE_LIST_URL,
    params: '',
    component: <InfoShippingTemplateListPage />,
    res: [
      SellerResource.SHOP_SETTINGS,
      SellerResource.SHOP_SETTINGS_TRAIT,
      SellerResource.SHOP_SETTINGS_SHOP_SYNC_READ,
      SellerResource.SHOP_SETTINGS_BASIC,
      SellerResource.SHOP_SETTINGS_EXPANSION,
    ],
  },
  {
    current_menu: 'info_access_history',
    path: '/info/access_history',
    params: '',
    component: <InfoAccessHistoryPage />,
    res: [SellerResource.ALL],
  },
];
