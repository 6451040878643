import { BaseText, Button, IconColoredWarning, IconLinkExternal, VStack, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PartnersBridge } from '@/utils/app_utils';

const AppNotSupportedPage: React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <Root>
      <VStack spacing={24} alignment='center'>
        <VStack spacing={8} alignment='center'>
          <IconColoredWarning size={40} />
          <BaseText kind='Heading_24_Bold' alignment='center'>
            <Trans t={t}>
              모바일에 최적화된 화면을
              <br />
              준비 중이에요.
            </Trans>
          </BaseText>
          <BaseText kind='Heading_15_SemiBold' color={semantic_colors.content.tertiary} alignment='center'>
            <Trans t={t}>
              모바일 브라우저 또는 PC를 이용해주세요.
              <br />
              불편을 드려 죄송합니다.
            </Trans>
          </BaseText>
        </VStack>
        <div>
          <Button
            kind='primary'
            size='small'
            endIcon={<IconLinkExternal />}
            onClick={() => {
              PartnersBridge.callSystemBrowserDeepLink(window.location.href);
            }}
          >
            {t('브라우저로 보기')}
          </Button>
        </div>
      </VStack>
    </Root>
  );
};

export default AppNotSupportedPage;

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${semantic_colors.background.surface};
`;
