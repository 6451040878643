import { Divider, HStack, Modal, Tooltip } from '@croquiscom/pds';
import dayjs from 'dayjs';
import { useUpdateAtom } from 'jotai/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { drawer_menu_open_atom } from '../atom';
import { useScrollDebounce } from '../hooks/useScrollDebounce';
import { ItemRow } from './ItemRow';
import { RollingBadge } from './RollingBadge';
import { CustomScrollContainer } from '@/components/common/styled';
import ClaimsTop from '@/components/daily_report/ClaimsTop';
import Disclaimer from '@/components/daily_report/Disclaimer';
import GrowthTarget from '@/components/daily_report/GrowthTarget';
import Hero from '@/components/daily_report/Hero';
import { useSendDailyReportPbl } from '@/components/daily_report/hooks/useSendDailyReportPbl';
import InsightNotice from '@/components/daily_report/InsightNotice';
import KeywordTop from '@/components/daily_report/KeywordTop';
import Management from '@/components/daily_report/Management';
import News from '@/components/daily_report/News';
import OrderTop from '@/components/daily_report/OrderTop';
import SalesPerformance from '@/components/daily_report/SalesPerformance';
import SalesTop from '@/components/daily_report/SalesTop';
import { useGetPlan } from '@/components/insight/hooks/useGetPlan';
import { useInfo } from '@/hooks/useInfo';
import useMediaQuery from '@/hooks/useMediaQuery';
import { useOnceTooltip } from '@/hooks/useOnceTooltip';
import { usePbl } from '@/hooks/usePbl';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { SellerResource, useGetDailyReportSalesConversionQuery } from 'api';
import { useModalState } from 'rui/pastel/unstable/modal';

export interface ShopInfoDailyReportProps {}

const TOOLTIP_HAS_COUPON = `Gnb.TooltipNotiDailyReport`;

export const ShopInfoDailyReport: React.FC<ShopInfoDailyReportProps> = () => {
  const { t } = useTranslation('main_frame');
  const { is_desktop } = useMediaQuery();
  const history = useHistory();
  const shop_prefix = useShopPrefix();
  const today = dayjs();
  const { pbl } = usePbl();
  const [{ isOpen, onOpen, onClose }] = useModalState(false);
  const { isAllowed } = useInfo();
  const { is_partner_plus } = useGetPlan();
  const should_show_daily_report = isAllowed(SellerResource.STATISTICS);
  const { is_show, hideTooltip } = useOnceTooltip({
    key: TOOLTIP_HAS_COUPON,
    compare_value: '매일 업데이트되는 데일리 리포트를 확인해 보세요.',
  });
  const { data } = useGetDailyReportSalesConversionQuery(
    {
      date_ymd: today.format('YYYY-MM-DD'),
      use_free_view_count: false,
    },
    {
      enabled: should_show_daily_report,
      onError() {},
    },
  );
  const order_data = data?.partner_plus_daily_report?.sales_order_report;
  const is_show_scroll = useScrollDebounce(is_show);
  const { sendPageView } = useSendDailyReportPbl(is_partner_plus);
  const onDrawerOpen = useUpdateAtom(drawer_menu_open_atom);

  if (!should_show_daily_report) {
    return null;
  }
  const openReport = () => {
    if (is_desktop) {
      sendPageView({});
      pbl({
        category: 'click',
        navigation: 'gnb_n_top_bar',
        object_id: 'pages',
        object_section: 'navigations',
        extra: JSON.stringify({
          item: '데일리 리포트',
        }),
      });
      hideTooltip();
      onOpen();
    } else {
      hideTooltip();
      onDrawerOpen(false);
      history.push(`${shop_prefix}/partner-plus/report`);
    }
  };

  return (
    <>
      <Tooltip
        opened={is_show_scroll}
        content={t('매일 아침 카카오톡에서 받으세요!')}
        openerTriggerEvent='none'
        placement='right'
        canClickOutside={false}
        zIndex={90}
        closeButton
        onClose={() => {
          if (is_show_scroll) {
            hideTooltip();
          }
        }}
      >
        <div>
          <ItemRow
            title={
              <HStack spacing={4} alignment='center'>
                {t('데일리 리포트')}
                {order_data && (
                  <RollingBadge
                    data_list={[
                      {
                        label: t('판매액'),
                        data: order_data?.order_amount_dod || 0,
                      },
                      {
                        label: t('주문수'),
                        data: order_data?.order_count_dod || 0,
                      },
                    ]}
                  />
                )}
              </HStack>
            }
            onClick={openReport}
            show_right
          />
        </div>
      </Tooltip>
      {is_desktop && (
        <Modal
          onCancel={onClose}
          onConfirm={onClose}
          opened={isOpen}
          title={t('데일리 리포트')}
          confirmText={t('확인')}
          contentProps={{ p: 0 }}
          width={391}
        >
          <CustomScrollContainer height='calc(100vh - 288px)'>
            <InsightNotice />
            <Hero date={today} />
            <Divider />
            <Disclaimer />
            <News date={today} onClose={onClose} is_partner_plus={is_partner_plus} />
            <GrowthTarget date={today} onClose={onClose} is_partner_plus={is_partner_plus} />
            <SalesPerformance date={today} onClose={onClose} is_partner_plus={is_partner_plus} />
            <Management date={today} onClose={onClose} is_partner_plus={is_partner_plus} />
            <OrderTop date={today} onClose={onClose} is_partner_plus={is_partner_plus} />
            <SalesTop date={today} onClose={onClose} is_partner_plus={is_partner_plus} />
            <ClaimsTop date={today} onClose={onClose} is_partner_plus={is_partner_plus} />
            <KeywordTop date={today} onClose={onClose} is_partner_plus={is_partner_plus} />
          </CustomScrollContainer>
        </Modal>
      )}
    </>
  );
};
