import {
  Badge,
  BaseText,
  HStack,
  IconArrowDecrease,
  IconArrowIncrease,
  IconColoredGraphUp,
  semantic_colors,
} from '@croquiscom/pds';
import type dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BlurArea from './common/BlurArea';
import Nodata from './common/Nodata';
import SectionTitle from './common/SectionTitle';
import { Card, ContentContainer } from './common/styles';
import { useGetDailyReportKeywordRanking } from './hooks/useGetDailyReportKeywordRanking';

interface Props {
  date: dayjs.Dayjs;
  onClose?: () => void;
  main_domain?: string;
  token?: string;
  is_partner_plus?: boolean;
}

const KeywordTop: React.FC<Props> = ({ date, onClose, main_domain, token, is_partner_plus }) => {
  const { t } = useTranslation('main_frame');
  const [show_freeview, setShowFreeview] = useState(false);
  const { data, isLoading } = useGetDailyReportKeywordRanking({
    date_ymd: date.format('YYYY-MM-DD'),
    use_free_view_count: show_freeview,
    main_domain,
    token,
  });
  const keyword_data = data?.partner_plus_daily_report?.keyword_ranking_report;

  return (
    <ContentContainer pb={50}>
      <SectionTitle
        object_section='어제 급상승 키워드 TOP 10'
        onClickLink={onClose}
        Icon={IconColoredGraphUp}
        main_domain={main_domain}
        link='/insight/keyword?ug=recent-keywords'
        is_partner_plus={is_partner_plus}
        is_partner_plus_only={keyword_data?.plus_only}
        description={t('지금 고객들이 주목하는 키워드를 확인해보세요.')}
      >
        {t('어제 급상승 키워드 TOP 10')}
      </SectionTitle>
      {keyword_data && keyword_data.keyword_rankings.length > 0 ? (
        <Card spacing={2}>
          <BlurArea
            is_partner_plus={is_partner_plus}
            is_loading={isLoading}
            main_domain={main_domain}
            onRequestFreeView={() => setShowFreeview(true)}
            object_section='어제 급상승 키워드 TOP 10'
            is_blur={keyword_data.is_blurred}
            used_free_view_count={keyword_data.free_view_count?.used_free_view_count || 0}
            max_free_view_count={keyword_data.free_view_count?.max_free_view_count || 0}
            postfix_text={t('어제 급상승 키워드 TOP10을 확인할 수 있어요.')}
          >
            <>
              {keyword_data?.keyword_rankings.slice(0, 5).map((item, index) => (
                <HStack pb={4} pt={4} alignment='center'>
                  <HStack spacing={12} alignment='center'>
                    <BaseText kind='Body_14_SemiBold'>{index + 1}</BaseText>
                    <HStack spacing={4} alignment='center'>
                      <BaseText>{item.keyword}</BaseText>
                      {item.ranking_changed === null || item.ranking_changed === undefined ? (
                        <Badge color='blue' size='small' kind='fill' icon={<IconArrowIncrease />}>
                          NEW
                        </Badge>
                      ) : item.ranking_changed > 0 ? (
                        <Badge color='green' size='small' kind='fill' icon={<IconArrowIncrease />}>
                          {item.ranking_changed} 단계
                        </Badge>
                      ) : (
                        item.ranking_changed < 0 && (
                          <Badge color='red' size='small' kind='fill' icon={<IconArrowDecrease />}>
                            {Math.abs(item.ranking_changed)} 단계
                          </Badge>
                        )
                      )}
                    </HStack>
                  </HStack>
                  <HStack ml='auto'>
                    <BaseText kind='Body_12_Medium' color={semantic_colors.content.secondary}>
                      {item.category_name}
                    </BaseText>
                  </HStack>
                </HStack>
              ))}
              {keyword_data?.keyword_rankings.slice(5).map((item, index) => (
                <HStack pb={4} pt={4} alignment='center'>
                  <HStack spacing={12} alignment='center'>
                    <BaseText kind='Body_14_SemiBold'>{index + 6}</BaseText>
                    <HStack spacing={4} alignment='center'>
                      <BaseText>{item.keyword}</BaseText>
                      {item.ranking_changed === null || item.ranking_changed === undefined ? (
                        <Badge color='blue' size='small' kind='fill' icon={<IconArrowIncrease />}>
                          NEW
                        </Badge>
                      ) : item.ranking_changed > 0 ? (
                        <Badge color='green' size='small' kind='fill' icon={<IconArrowIncrease />}>
                          {item.ranking_changed}
                        </Badge>
                      ) : (
                        item.ranking_changed < 0 && (
                          <Badge color='red' size='small' kind='fill' icon={<IconArrowDecrease />}>
                            {Math.abs(item.ranking_changed)}
                          </Badge>
                        )
                      )}
                    </HStack>
                  </HStack>
                  <HStack ml='auto'>
                    <BaseText kind='Body_12_Medium' color={semantic_colors.content.secondary}>
                      {item.category_name}
                    </BaseText>
                  </HStack>
                </HStack>
              ))}
            </>
          </BlurArea>
        </Card>
      ) : (
        <Nodata />
      )}
    </ContentContainer>
  );
};

export default KeywordTop;
