import {
  BaseText,
  VStack,
  semantic_colors,
  HStack,
  colors,
  Badge,
  IconArrowIncrease,
  IconArrowDecrease,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import type { Dayjs } from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ComparisonBadge from './ComparisonBadge';
import { DailyReportProductStat } from 'api';

export type ProductCardType = 'order' | 'sales' | 'claim';

interface Props {
  type: ProductCardType;
  is_blur?: boolean;
  is_conversion?: boolean;
  product: DailyReportProductStat;
  base_date?: Dayjs;
}

const ProductCard: React.FC<Props> = ({ type, is_blur, is_conversion, product, base_date }) => {
  const { t } = useTranslation('main_frame');

  return (
    <CardContainer spacing={8} is_blur={is_blur}>
      <ProductImage src={product.image_url}>
        <Rank>
          <BaseText kind='Body_12_SemiBold'>{product.ranking}</BaseText>
        </Rank>
        {product.is_zigzin && (
          <ZigzingTag>
            <BaseText kind='Caption_10_Bold'>{t('직진')}</BaseText>
          </ZigzingTag>
        )}
      </ProductImage>
      {is_conversion ? (
        <VStack spacing={3} alignment='leading'>
          <ComparisonBadge
            size='small'
            value={product.order_count_dod || 0}
            prefix_text={t('전날')}
            josa='과'
          ></ComparisonBadge>
          <ComparisonBadge
            size='small'
            value={product.order_count_wow || 0}
            prefix_text={t('지난주 {{date}}', { date: base_date?.subtract(1, 'day')?.format('dddd') })}
          ></ComparisonBadge>
          <ComparisonBadge
            size='small'
            value={product.order_count_increase_rate_of_last7day_average || 0}
            prefix_text={t('7일 평균')}
          ></ComparisonBadge>
        </VStack>
      ) : (
        <VStack>
          <ProductName kind='Body_12_Regular'>{product.name}</ProductName>
          {type === 'order' && (
            <HStack spacing={4} alignment='center'>
              <BaseText kind='Body_12_SemiBold' color={semantic_colors.content.secondary}>
                주문 {product.order_count || 0}건
              </BaseText>
              {product.ranking_changed === null || product.ranking_changed === undefined ? (
                <Badge color='blue' size='small' kind='fill' icon={<IconArrowIncrease />}>
                  NEW
                </Badge>
              ) : product.ranking_changed > 0 ? (
                <Badge color='green' size='small' kind='fill' icon={<IconArrowIncrease />}>
                  {product.ranking_changed} 단계
                </Badge>
              ) : (
                product.ranking_changed < 0 && (
                  <Badge color='red' size='small' kind='fill' icon={<IconArrowDecrease />}>
                    {Math.abs(product.ranking_changed)} 단계
                  </Badge>
                )
              )}
            </HStack>
          )}
          {type === 'sales' && (
            <HStack spacing={4} alignment='center'>
              <BaseText kind='Body_12_SemiBold' color={semantic_colors.content.secondary}>
                주문 {product.order_item_count_7days_sum || 0}건
              </BaseText>
              {product.ranking_changed === null || product.ranking_changed === undefined ? (
                <Badge color='blue' size='small' kind='fill' icon={<IconArrowIncrease />}>
                  NEW
                </Badge>
              ) : product.ranking_changed > 0 ? (
                <Badge color='green' size='small' kind='fill' icon={<IconArrowIncrease />}>
                  {product.ranking_changed} 단계
                </Badge>
              ) : (
                product.ranking_changed < 0 && (
                  <Badge color='red' size='small' kind='fill' icon={<IconArrowDecrease />}>
                    {Math.abs(product.ranking_changed)} 단계
                  </Badge>
                )
              )}
            </HStack>
          )}
          {type === 'claim' && (
            <LabelBox spacing={4}>
              <LabelText kind='Body_12_SemiBold' color={semantic_colors.content.secondary}>
                주문 {product.order_count || 0}건
              </LabelText>
              <LabelText kind='Body_12_SemiBold' color={semantic_colors.content.secondary}>
                반품 {product.refund_count || 0}건
              </LabelText>
              <LabelText kind='Body_12_SemiBold' color={semantic_colors.content.secondary}>
                취소 {product.cancel_count || 0}건
              </LabelText>
            </LabelBox>
          )}
        </VStack>
      )}
    </CardContainer>
  );
};

export default ProductCard;

const CardContainer = styled(HStack)<{ is_blur?: boolean }>`
  padding-right: 16px;
  filter: ${({ is_blur }) => (is_blur ? 'blur(5px)' : 'none')};
`;

const ProductImage = styled(VStack)<{ src: string }>`
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  background-image: url(${({ src }) => src});
  background-size: cover;
`;

const Rank = styled(VStack)`
  width: 20px;
  height: 20px;
  background-color: ${semantic_colors.background.base_inverse};
  color: ${semantic_colors.content.on_color};
  align-items: center;
  justify-content: center;
`;
const ZigzingTag = styled(VStack)`
  margin: auto 2px 2px;
  width: 30px;
  height: 20px;
  border-radius: 100px;
  background-color: ${colors.purple500};
  color: ${semantic_colors.content.on_color};
  align-items: center;
  justify-content: center;
`;
const ProductName = styled(BaseText)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;
const LabelText = styled(BaseText)`
  white-space: nowrap;
`;
const LabelBox = styled(HStack)`
  justify-content: space-between;
`;
