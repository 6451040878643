import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { AlertItemType } from '../types';
import { checkAlertDismiss, setAlertDismiss } from '../utils';
import { GetAlertListQuery, SellerResource, api_getAlertList } from '@/api';
import { useInfo } from '@/hooks/useInfo';
import { PartnerPlusNavUrl } from 'rui/components/URL';

export const GET_ALERT_LIST_QUERY_KEY = 'getAlertList';

const useGetMetaContract = (
  meta_contract?: GetAlertListQuery['getShopMetaInternalContractListOfShop'],
): AlertItemType | null => {
  const { t } = useTranslation('main_frame');
  const { isAllowed } = useInfo();
  const access_allowed = isAllowed([SellerResource.ALL]);
  if (!access_allowed) return null;
  const current_meta_contract = meta_contract?.item_list?.[0];
  if (!current_meta_contract) return null;

  return {
    title: t('참여한 기획전의 쿠폰 분담율 승인이 필요해요'),
    description: t('기간 내 미동의시 쿠폰 발급이 불가능합니다.'),
    extra_text:
      meta_contract.total_count > 1
        ? t('{{ title }} 외 {{ extra_count }}건', {
            title: current_meta_contract.title,
            extra_count: meta_contract.total_count - 1,
          })
        : t('{{ title }}', {
            title: current_meta_contract.title,
          }),
    link: '/shop_internal_contract?is_pending=1',
    important: true,
  };
};

const useGetContract = (notification_queue?: GetAlertListQuery['getSellerNotificationQueue']): AlertItemType | null => {
  const { t } = useTranslation('main_frame');
  const { isAllowed } = useInfo();
  const access_allowed = isAllowed([SellerResource.ALL]);
  if (!access_allowed) return null;
  const current_shop_contract = notification_queue?.[0];
  if (!current_shop_contract) return null;

  const url = '/shop_contract?is_unsigned=1';
  return current_shop_contract.shop_contract_id
    ? {
        title: t('기한 내 계약 확인 및 서명이 필요해요'),
        description: t('체결 기간이 지나기 전에 계약 내용을 검토하고 서명해주세요.'),
        extra_text: t('계약번호 : {{ contract_id }}', { contract_id: current_shop_contract.shop_contract_id }),
        link: `${url}&id=${current_shop_contract.shop_contract_id}`,
        important: true,
      }
    : {
        title: t('기한 내 계약 확인 및 서명이 필요해요'),
        description: t('여러건의 계약이 대기중입니다. 체결 기간이 지나기 전에 계약 내용을 검토하고 서명해주세요.'),
        link: url,
        important: true,
      };
};

const useGetSubscription = (shop_subscription?: GetAlertListQuery['shop_subscription']): AlertItemType | null => {
  const { t } = useTranslation('main_frame');
  const { isAllowed } = useInfo();
  if (shop_subscription?.current_payment_status !== 'FAILED') return null;
  if (!['SUBSCRIBED', 'CANCELLING'].includes(shop_subscription.status)) return null;
  const access_allowed = isAllowed([
    SellerResource.SALES_INSIGHT_READ,
    SellerResource.KEYWORD_INSIGHT_READ,
    SellerResource.CUSTOMER_INSIGHT_READ,
    SellerResource.COMPETITOR_INSIGHT_READ,
  ]);
  if (!access_allowed) return null;
  return {
    title: t('파트너플러스 구독료 결제가 실패했어요'),
    description: t('파트너플러스 인사이트 이용이 불가능합니다. 결제 정보를 확인하세요.'),
    extra_text: t('결제 실패 구독료 {{ price }}원', { price: shop_subscription.price.toLocaleString() }),
    link: PartnerPlusNavUrl.MANAGEMENT_SUBSCRIPTION,
  };
};

const useGetAdBalance = (
  notification: NonNullable<GetAlertListQuery['ad_running_shop_info']>['notification'],
): AlertItemType | null => {
  const is_running = Boolean(notification?.alert_min_budget_enabled);
  const { t } = useTranslation('main_frame');
  const { info, isAllowed } = useInfo();
  if (!is_running) return null;
  const access_allowed = isAllowed([SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT]);
  if (!access_allowed) return null;
  const wallet = info?.wallet;
  const total_balance =
    (wallet?.cash ?? 0) +
    (wallet?.ad_display_point ?? 0) +
    (wallet?.attention_ad_point || 0) +
    (wallet?.message_point || 0) +
    (wallet?.fbk_ad_point || 0);
  const lack = total_balance <= 0;
  if (!lack) return null;
  return {
    title: t('광고비가 모두 소진되었습니다'),
    description: t('광고 코인을 충전하고 광고를 원활하게 진행하세요.'),
    extra_text: t('광고비 잔액 {{ balance }}원', { balance: total_balance.toLocaleString() }),
    link: '/wallet',
    dismiss_key: 'ad_balance_lack',
  };
};

const useGetCustomerExtension = (
  customer_extension_state?: GetAlertListQuery['getCustomerExpansionStateOfShop'],
): AlertItemType | null => {
  if (!customer_extension_state?.allotment_state && !customer_extension_state?.period_allotment_state) {
    return null;
  }
  if (customer_extension_state?.allotment_state) {
    return {
      title: '고객 확장프로그램 분담 약정에 동의해 주세요.',
      description: '프로그램 진행을 위해 분담률 약정 동의가 필요합니다.',
      link: '/seller_support_program/customer_extension#allotment',
      important: true,
    };
  } else {
    return {
      title: '고객 확장 프로그램 기간제 분담률 적용에 대한 동의가 필요해요',
      description: '',
      link: '/seller_support_program/customer_extension#period-allotment',
      important: true,
    };
  }
};

export function useGetAlertList() {
  const [, setDismissCheckTime] = useState(Date.now());

  const { data, error, refetch } = useQuery(
    [GET_ALERT_LIST_QUERY_KEY],
    async () => {
      const alert_list = await api_getAlertList({}, { no_alert: true });
      return alert_list.data;
    },
    {
      onError: () => {},
      keepPreviousData: true,
      staleTime: 60000,
      cacheTime: 60000,
      refetchInterval: 5 * 60 * 1000,
    },
  );

  const shop_meta_internal_contract = useGetMetaContract(data?.getShopMetaInternalContractListOfShop);
  const shop_contract = useGetContract(data?.getSellerNotificationQueue);
  const shop_subscription = useGetSubscription(data?.shop_subscription);
  const shop_balance = useGetAdBalance(data?.ad_running_shop_info?.notification);
  const shop_customer_extension = useGetCustomerExtension(data?.getCustomerExpansionStateOfShop);

  const alert_list = [
    shop_customer_extension,
    shop_meta_internal_contract,
    shop_contract,
    shop_subscription,
    shop_balance,
  ].filter((item: AlertItemType | null) => {
    return item && (item.dismiss_key ? !checkAlertDismiss(item.dismiss_key) : true);
  }) as AlertItemType[];

  return {
    data: alert_list,
    error,
    refetch,
    updateDismiss: (dismiss_key: string) => {
      setAlertDismiss(dismiss_key);
      setDismissCheckTime(Date.now());
    },
  };
}
