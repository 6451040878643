import { Modal, BaseText, VStack, semantic_colors } from '@croquiscom/pds';
import React from 'react';
import ExperienceGroupScheduleExample from '@/assets/images/promotion/experience_group_schedule_example.png';

interface Props {
  is_open: boolean;
  onClose: () => void;
}

export const ExperienceGroupScheduleExampleModal: React.FC<Props> = ({ is_open, onClose }) => {
  return (
    <Modal
      title='체험단 프로모션 신청'
      confirmText='확인'
      width={600}
      contentProps={{
        maxHeight: 929,
      }}
      opened={is_open}
      onConfirm={onClose}
      onCancel={onClose}
    >
      <VStack spacing={20}>
        <VStack spacing={16}>
          <VStack spacing={4}>
            <BaseText kind='Body_14_Bold'>체험단 프로모션이란?</BaseText>
            <BaseText>
              상품의 신규 리뷰를 확보하여 상품 노출과 구매전환율을 높일 수 있습니다.
              <br />
              체험단 프로모션에 신청한 상품은 지그재그 체험단에서 노출됩니다.
            </BaseText>
          </VStack>
          <VStack spacing={4}>
            <BaseText kind='Body_14_Bold'>체험단 신청 및 고객 선정 기준</BaseText>
            <BaseText>
              스토어 즐겨찾기를 한 고객에 한해 체험단 신청을 받습니다.
              <br />
              높은 퀄리티의 리뷰를 작성하는 고객들을 우선 선정합니다.
            </BaseText>
          </VStack>
        </VStack>

        <VStack spacing={16}>
          <BaseText kind='Body_14_Bold'>체험단 일정 예시</BaseText>
          <img src={ExperienceGroupScheduleExample} alt='체험단 운영 일정 예시'></img>
          <BaseText color={semantic_colors.content.secondary}>
            체험단 프로모션 신청 마감 후, 일주일 간 고객에게 체험단 신청을 받습니다.
            <br />
            고객의 구매 기간은 체험단 종료 후 5일간 입니다. 기간 내 상품을 받아볼 수 있도록 1일 내 출고를 권장합니다.
            <br />
            리뷰 작성 기간은 체험단 선정일 후 14일 간이며 체험단 리뷰는 리뷰 관리 메뉴에서 확인할 수 있습니다.
          </BaseText>
        </VStack>
      </VStack>
    </Modal>
  );
};
