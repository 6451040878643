import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import React from 'react';
import { fixed_bottom_area_atom } from '../atom';
import AdPromotionPopup from './ad_promotion/AdPromotionPopup';
import { ImageNoticePopupList, TextNoticePopupList } from './constants';
import { CsCenterButtons } from './CsCenterButtons';
import { FeedbackOutlinkButton } from './FeedbackOutlinkButton';
import { HelpPopupButton } from './HelpPopupButton';
import { ImageNoticePopup } from './ImageNoticePopup';
import { InsightFeedbackButton } from './insight_feedback/InsightFeedbackButton';
import { NoticePopup } from './NoticePopup';
import { ScrollTopButton } from './ScrollTopButton';
import { SurveyButton } from './SurveyButton';
import { useLocation } from '@/components/route/LegacyRouterCompat';
import { INSIGHT_ROUTES } from '@/routes/each/insight';
import { PARTNER_PLUS_ROUTES } from '@/routes/each/partner_plus';
import { HelpPopup } from 'rui/components/widget';

export const Floating = () => {
  const fixed_bottom_area = useAtomValue(fixed_bottom_area_atom);
  const location = useLocation();
  const insight_route =
    INSIGHT_ROUTES.find((route) => location.pathname.includes(route.path)) ||
    PARTNER_PLUS_ROUTES.find((route) => location.pathname.includes(route.path));
  return (
    <Container fixed_bottom_area={fixed_bottom_area}>
      <HelpPopupButton />
      <SurveyButton />
      {insight_route?.hasInsightFeedback ? (
        <InsightFeedbackButton pageTitle={insight_route.title} />
      ) : (
        <FeedbackOutlinkButton />
      )}
      <CsCenterButtons />
      <ScrollTopButton />
      <HelpPopup />
      {/* TODO: 아래 팝업들 지우기 */}
      {TextNoticePopupList.map((notice) => (
        <NoticePopup key={notice.notice_key()} {...notice} />
      ))}
      {ImageNoticePopupList.map((notice) => (
        <ImageNoticePopup
          key={notice.notice_key()}
          popup_type={notice.popup_type}
          notice_key={notice.notice_key}
          title={notice.title}
          badge={notice.badge}
          image_url={notice.image_url}
          start_date={notice.start_date}
          content={notice.content}
          end_date={notice.end_date}
          link={notice.link}
          links={notice.links}
          is_allowed={notice.is_allowed}
        />
      ))}
      <AdPromotionPopup />
    </Container>
  );
};

const Container = styled.div<{ fixed_bottom_area: number }>`
  position: fixed;
  right: 20px;
  bottom: ${({ fixed_bottom_area }) => 20 + fixed_bottom_area}px;
  display: flex;
  align-items: flex-end;
  column-gap: 10px;
  z-index: 100;
`;
