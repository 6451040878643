import { PageHeader, SectionTitle } from '@croquiscom/pds';
import React, { lazy } from 'react';
import { RoutesType } from '../types';
import { ChannelExtensionNav } from '@/components/channel_extension/ChannelExtensionNav';
const ChannelExtensionIntroPage = lazy(() => import('@/pages/channel_extension/ChannelExtensionIntroPage'));
const ChannelExtensionStatusPage = lazy(() => import('@/pages/channel_extension/ChannelExtensionStatusPage'));

export const CHANNEL_EXTENSION_ROUTES: RoutesType[] = [
  {
    current_menu: 'channel_extension',
    path: '/channel_extension/intro',
    params: '',
    component: (
      <>
        <PageHeader border={false}>
          <SectionTitle title='원클릭 판매채널 확장' />
        </PageHeader>
        <ChannelExtensionNav tab='intro' />
        <ChannelExtensionIntroPage />
      </>
    ),
  },
  {
    current_menu: 'channel_extension',
    path: '/channel_extension/status',
    params: '',
    component: (
      <>
        <PageHeader border={false}>
          <SectionTitle title='원클릭 판매채널 확장' />
        </PageHeader>
        <ChannelExtensionNav tab='status' />
        <ChannelExtensionStatusPage />
      </>
    ),
  },
];
