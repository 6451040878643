import { Button, PageHeader, SectionTitle } from '@croquiscom/pds';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AD_GUIDE_OPTIONS } from '@/components/ad/common/constants';
import { useHelpConstants } from '@/hooks/useHelpConstants';

interface ExhibitionHeaderProps {
  title: string;
}

const ExhibitionHeader: FC<ExhibitionHeaderProps> = ({ title }) => {
  const { t } = useTranslation('promotion');
  const { data } = useHelpConstants(AD_GUIDE_OPTIONS);

  return (
    <PageHeader>
      <SectionTitle
        title={title}
        description={t('고객 대상으로 발행한 프로모션/이벤트 페이지를 관리하는 영역입니다.')}
        actions={
          <a href={data['프로모션 관리 소개서']} target='_blank' rel='noreferrer' className='anchor'>
            <Button kind='primary'>{t('프로모션 관리 소개서')}</Button>
          </a>
        }
      />
    </PageHeader>
  );
};

export default ExhibitionHeader;
