import { IconCircleWon, semantic_colors } from '@croquiscom/pds';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ItemRow } from './ItemRow';
import { useGetAdDisplayInfoQuery } from '@/components/advertisement/ad_display/hooks/useGetAdDisplayInfoQuery';
import { SiteId } from '@/constants/site';
import { useInfo } from '@/hooks/useInfo';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import displayUtils from '@/utils/displayUtils';
import { SellerResource, useGetAdShopV3Query } from 'api';

export interface ShopInfoZCoinProps {}

export const ShopInfoZCoin: React.FC<ShopInfoZCoinProps> = () => {
  const { t } = useTranslation('main_frame');
  const shop_prefix = useShopPrefix();
  const { data: shop } = useGetAdShopV3Query({ site_id: SiteId.지그재그 });
  const { data: adDisplayInfo } = useGetAdDisplayInfoQuery();
  const {
    info: { wallet },
    isAllowed,
  } = useInfo();

  const coin = displayUtils.getBalanceFromWallet(wallet);
  const should_show_z_coin =
    ((!!shop?.ad3_center_shop?.agreement_at || !!adDisplayInfo?.agreementDate) &&
      isAllowed(SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT)) ||
    !!coin;

  if (!should_show_z_coin) {
    return null;
  }
  return (
    <ItemRow
      icon={<IconCircleWon size={16} color={semantic_colors.brand.primary} />}
      title={t('광고 코인')}
      to={`${shop_prefix}/wallet`}
    >
      {t('{{ amount }}원', { amount: displayUtils.formatNumber(coin) })}
    </ItemRow>
  );
};
