import { Modal, BaseText, VStack, semantic_colors } from '@croquiscom/pds';
import React from 'react';
import ExperienceGroupProductExposeExample from '@/assets/images/promotion/experience_group_product_expose_example.png';

interface Props {
  is_open: boolean;
  onClose: () => void;
}

export const ExperienceGroupProductExposeExampleModal: React.FC<Props> = ({ is_open, onClose }) => {
  return (
    <Modal
      title='노출 예시 보기'
      confirmText='확인'
      width={900}
      contentProps={{
        maxHeight: 1025,
      }}
      opened={is_open}
      onConfirm={onClose}
      onCancel={onClose}
    >
      <VStack spacing={24}>
        <VStack spacing={16}>
          <BaseText kind='Heading_20_SemiBold'>체험단 프로모션 예시</BaseText>
          <div>
            <BaseText>정된 체험단 상품은 </BaseText>
            <BaseText color={semantic_colors.accent.primary}>
              마이페이지 '지그재그 체험단' 메뉴 , 체험단 PLP, 체험단 PDP, (뷰티 스토어 한정) 뷰티관 체험단
            </BaseText>
            <BaseText>구좌에서 노출됩니다.</BaseText>
            <br />
            <BaseText>노출 구좌는 점진적으로 확대 예정입니다.</BaseText>
          </div>
        </VStack>
        <VStack spacing={16}>
          <img src={ExperienceGroupProductExposeExample} alt='체험단 운영 일정 예시'></img>
        </VStack>
      </VStack>
    </Modal>
  );
};
