import { IconArrowChevronDown, IconBeta, IconNew, colors, semantic_colors, text_styles } from '@croquiscom/pds';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { MenuEntry } from './types';
import { ShopLink } from '@/components/route/ShopLink';

export interface MenuParentItemButtonProps {
  entry: MenuEntry;
  is_selected?: boolean;
  onClick?: () => void;
}

export const MenuParentItemButton: React.FC<MenuParentItemButtonProps> = ({ entry, is_selected, onClick }) => {
  const label = (
    <>
      {entry.label}
      {entry.is_new && (
        <StyledIcon>
          <IconNew size={8} color={semantic_colors.brand.primary} />
        </StyledIcon>
      )}
      {entry.is_beta && (
        <StyledIcon>
          <IconBeta size={21} color={semantic_colors.accent.primary} />
        </StyledIcon>
      )}
      {entry.badge}
    </>
  );
  if (entry.items == null && entry.href != null && entry.href.startsWith('http')) {
    return (
      <StyledMenuA href={entry.href} target='_blank' onClick={onClick} is_selected={is_selected} is_singular>
        {entry.icon && <StyledMenuIcon>{entry.icon}</StyledMenuIcon>}
        <StyledMenuLabel>{label}</StyledMenuLabel>
      </StyledMenuA>
    );
  } else if (entry.items == null && entry.href != null) {
    return (
      <StyledMenuLink to={entry.href} onClick={onClick} is_selected={is_selected} is_singular>
        {entry.icon && <StyledMenuIcon>{entry.icon}</StyledMenuIcon>}
        <StyledMenuLabel>{label}</StyledMenuLabel>
      </StyledMenuLink>
    );
  }
  return (
    <StyledMenuButton aria-expanded={is_selected} onClick={onClick} is_selected={is_selected} type='button'>
      {entry.icon && <StyledMenuIcon>{entry.icon}</StyledMenuIcon>}
      <StyledMenuLabel>{label}</StyledMenuLabel>
      {entry.items != null && (
        <StyledMenuArrow is_selected={is_selected}>
          <IconArrowChevronDown size={12} color='currentColor' />
        </StyledMenuArrow>
      )}
    </StyledMenuButton>
  );
};

const StyledMenuButton = styled('button', {
  shouldForwardProp: (prop) => prop !== 'is_selected' && prop !== 'is_singular',
})<{ is_selected?: boolean; is_singular?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  margin: 0;
  padding: 8px 20px;
  border: none;
  box-sizing: border-box;
  text-align: left;
  cursor: pointer;
  text-decoration: none;
  ${text_styles.Body_13_Medium};
  background-color: ${semantic_colors.background.surface};
  color: ${semantic_colors.content.primary};
  transition:
    0.1s background-color linear,
    0.1s color linear;
  &:hover,
  &:focus-visible {
    background-color: ${colors.gray200};
  }
  &:active {
    background-color: ${colors.gray300};
  }
  &:visited {
    color: ${semantic_colors.content.primary};
  }
  ${({ is_selected, is_singular }) =>
    is_selected &&
    !is_singular &&
    css`
      color: ${semantic_colors.accent.primary};
      ${text_styles.Body_13_SemiBold};
      &:hover,
      &:focus-visible {
        background-color: ${colors.blue50};
      }
      &:active {
        background-color: ${colors.blue100};
      }
    `}
  ${({ is_selected, is_singular }) =>
    is_selected &&
    is_singular &&
    css`
      color: ${semantic_colors.accent.primary};
      ${text_styles.Body_13_SemiBold};
      background-color: ${colors.blue100};
      &:hover,
      &:focus-visible,
      &:active {
        background-color: ${colors.blue100};
      }
      &:visited {
        color: ${semantic_colors.accent.primary};
      }
    `}
`;

const StyledMenuLink = StyledMenuButton.withComponent(ShopLink);
const StyledMenuA = StyledMenuButton.withComponent('a');

const StyledMenuIcon = styled.span`
  line-height: 0;
`;

const StyledMenuLabel = styled.span`
  flex: 1 0 auto;
`;

const StyledMenuArrow = styled.span<{ is_selected?: boolean }>`
  line-height: 0;
  color: ${semantic_colors.content.secondary};
  transition: 0.1s transform linear;
  ${({ is_selected }) =>
    is_selected &&
    css`
      color: ${semantic_colors.accent.primary};
      transform: rotate(180deg);
    `}
`;

const StyledIcon = styled.span`
  display: inline-block;
  margin-left: 2px;
  vertical-align: 5px;
  line-height: 0;
`;
