import { colors, IconLinkExternal, text_styles } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { usePbl } from '@/hooks/usePbl';
import { PartnersBridge } from '@/utils/app_utils';

export const ContactSection: React.FC = React.memo(() => {
  const { pbl } = usePbl();

  const handlePartnerLoungeClick = () => {
    pbl({
      navigation: 'gnb_n_top_bar',
      category: 'click',
      object_id: 'sites',
      object_section: 'family_sites',
      extra: JSON.stringify({ item: '파트너라운지' }),
    });

    PartnersBridge.callInAppBrowserDeepLink('https://bit.ly/3A4BvbX');
  };

  const handleIpRightClick = () => {
    pbl({
      navigation: 'gnb_n_top_bar',
      category: 'click',
      object_id: 'sites',
      object_section: 'family_sites',
      extra: JSON.stringify({ item: '지식재산권 보호 센터' }),
    });
    PartnersBridge.callInAppBrowserDeepLink('https://partnerlounge.kakaostyle.com/ip-right');
  };

  return (
    <StyledContainer>
      <div>
        <Label>관련 사이트 바로가기</Label>
        <span onClick={handlePartnerLoungeClick}>
          <DescLink>
            파트너라운지
            <IconLinkExternal size={12} color={colors.gray700} />
          </DescLink>
        </span>
        <span onClick={handleIpRightClick}>
          <DescLink>
            지식재산권 보호 센터
            <IconLinkExternal size={12} color={colors.gray700} />
          </DescLink>
        </span>
      </div>

      <div>
        <Label>고객센터</Label>
        <Desc>(02) 1670-8051</Desc>
      </div>

      <div>
        <Label>운영 시간</Label>
        <Desc>
          평일 09:00~18:00
          <br />
          토/일/공휴일 휴무
        </Desc>
      </div>
    </StyledContainer>
  );
});

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 20px 20px 50px;
`;

const Label = styled.div`
  ${text_styles.Caption_11_SemiBold};
  color: ${colors.gray600};
`;

const Desc = styled.div`
  display: flex;
  align-items: center;
  ${text_styles.Body_12_Regular};
  color: ${colors.gray700};
  margin-top: 4px;
`;

const DescLink = styled(Desc)`
  cursor: pointer;
  display: flex;
  column-gap: 4px;
`;
