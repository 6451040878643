import { Divider, HStack, PageHeader, SectionTitle, TextButton } from '@croquiscom/pds';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ExampleModal from '../modal/ExampleModal';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { useModalState } from 'rui/pastel/unstable/modal';

const PriceOptimizationPageHeader = () => {
  const [{ isOpen, onClose, onOpen }] = useModalState(false);
  const history = useHistory();
  const shop_prefix = useShopPrefix();
  return (
    <PageHeader>
      <SectionTitle
        title='가격 최적화 관리'
        description={
          '노출 확대 기회가 있는 상품과 가격을 추천합니다. 추천 가격을 적용해 상품의 경쟁력을 향상시켜 보세요. 대상 상품은 매주 월요일 업데이트 됩니다.'
        }
        textButton={
          <HStack spacing={6} alignment='center'>
            <TextButton kind='link' onClick={onOpen}>
              노출 확대 안내
            </TextButton>
            <Divider direction='vertical' length={12} />
            <TextButton kind='link' onClick={() => history.push(`${shop_prefix}/promotion/price_optimization/intro`)}>
              서비스 소개
            </TextButton>
          </HStack>
        }
      />
      <ExampleModal opened={isOpen} onClose={onClose} />
    </PageHeader>
  );
};

export default PriceOptimizationPageHeader;
