import { Button, SectionTitle, colors, HStack, Divider } from '@croquiscom/pds';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CREATE_CATALOG_PRODUCT_GUIDE_LINK } from './constants';
import { GUIDE_CATALOG_ITEM_DETAIL } from '@/components/common/guide/constants';

const CatalogDetailSectionTitle: React.FC<{ is_new?: boolean }> = ({ is_new }) => {
  const { t } = useTranslation('catalog');
  return (
    <SectionTitle
      title={is_new ? t('상품 등록') : t('상품 수정')}
      description={
        <>
          <span style={{ color: colors.red500 }}>*</span> {t('표시는 필수로 입력해야 할 항목입니다.')}
        </>
      }
      {...(is_new && {
        actions: (
          <Button onClick={() => window.open(CREATE_CATALOG_PRODUCT_GUIDE_LINK, '_blank', 'noreferrer')}>
            {t('상품 등록 가이드')}
          </Button>
        ),
        textButton: (
          <HStack spacing={6} alignment='center'>
            {GUIDE_CATALOG_ITEM_DETAIL.NEW_1}
            <Divider direction='vertical' style={{ height: 12 }} />
            {GUIDE_CATALOG_ITEM_DETAIL.NEW_2}
            <Divider direction='vertical' style={{ height: 12 }} />
            {GUIDE_CATALOG_ITEM_DETAIL.NEW_3}
          </HStack>
        ),
      })}
    />
  );
};

export default CatalogDetailSectionTitle;
